import Button from "../Button";
import {FaCaretDown} from "react-icons/fa";

const MenuButton = ({
    isOpen,
    variant,
    onClick,
    label,
    icon,
}) =>{
    return(
        <Button className={`menu-button ${isOpen ? "rotate-icon" : ""}`} onClick={onClick} variant={variant} iconPosition="right">{icon}{label}</Button>
    )
}

MenuButton.defaultProps = {
    variant:"primary",
    onClick: () =>{},
    icon:<FaCaretDown/>,
    label:"Menu"
}

export default MenuButton;