import { isEmpty } from "lodash";
import {
	PostHandler,
	QueryHandler
} from "../event-driven/core-utils/core-decorators";
import AxiosAPIInterface from "./axios-api-interface";

export default class ExternalSystemIntegrationAPI extends AxiosAPIInterface {
	constructor() {
		if (ExternalSystemIntegrationAPI._instance) {
			return ExternalSystemIntegrationAPI._instance;
		}
		super({ resoucePath: "/integration" });
		ExternalSystemIntegrationAPI._instance = this;
	}

	@QueryHandler("GET_QUICKBOOKS_AUTH")
	getQBAuthLink() {
		return this.get({ params: { connect: "QUICKBOOKS" } });
	}

	@QueryHandler("GET_INTEGRATION_STATUS")
	getIntegrationDetails(accountType) {
		return this.get({ path: "/status", params: { accountType } }).then(
			integrationStatus => {
				if (!isEmpty(integrationStatus)) {
					if (integrationStatus.syncDetails)
						integrationStatus.syncDetails = JSON.parse(
							integrationStatus.syncDetails
						);
					if (integrationStatus.additionInfo)
						integrationStatus.additionInfo =
							typeof integrationStatus.additionInfo === "string"
								? JSON.parse(integrationStatus.additionInfo)
								: integrationStatus.additionInfo;
					if (integrationStatus.preferences)
						integrationStatus.preferences = JSON.parse(
							integrationStatus.preferences
						);
					return integrationStatus;
				}
				return {};
			}
		);
	}

	@PostHandler("DISCONNECT_FROM_QUICKBOOKS")
	disconnectQBO() {
		return this.delete({
			params: {
				disconnect: "QUICKBOOKS"
			}
		});
	}

	@PostHandler("SYNC_TO_QBO")
	syncToQBO({ body }) {
		return this.post({ body, path: "/sync/outbound" });
	}

	@PostHandler("SAVE_QBO_SYNC_PREFERENCE")
	qboSyncPreference(apiData) {
		return this.put({ path: "/preference", body: apiData });
	}

	@PostHandler("GET_SIGNED_URL")
	getSignedUrl() {
		return this.post({ path: "/qbd/qbwc/signedurl" });
	}

	@PostHandler("RECONNECT_QUICKBOOKS_DESKTOP")
	reconnectQuickBooksDesktop() {
		return this.post({ path: "/qbd/qbwc/reconnect" });
	}

	@PostHandler("CONNECT_QUICKBOOKS_DESKTOP")
	connectQuickBooksDesktop() {
		return this.post({ path: "/qbd/qbwc" });
	}

	@PostHandler("DISCONNECT_FROM_QUICKBOOKS_DESKTOP")
	disconnectQuickBooksDesktop() {
		return this.post({ path: "/qbd/qbwc/disconnect" });
	}
}
