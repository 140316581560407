import { PostHandler, QueryHandler } from "../event-driven/core-utils/core-decorators";
import AxiosAPIInterface from "./axios-api-interface";

export default class PreferencesAPI extends AxiosAPIInterface {
	constructor() {
		if (PreferencesAPI._instance) {
			return PreferencesAPI._instance;
		}
		super({ resoucePath: "/preferences" });
		PreferencesAPI._instance = this;
	}

	@PostHandler("UPDATE_PREFERENCES")
	updatePreference(body) {
		return this.put({ body });
	}


	@QueryHandler("GET_PREFERENCE_BY_USERID")
	getPreferenceByUserId({ userId }) {
        return this.get({ path: `/${userId}`})
	}
}
