import { EventStatus } from "../core-utils/constants";
import { ReadOnly } from "../core-utils/core-decorators";

// Enhancement - Emails/Notification with stack and other data for effective debugging.
export default class EventLogs{
    constructor(){
        if (EventLogs._instance) {
			return EventLogs._instance;
        }
        this._eventLogs = new Array();
        this._moduleSessionLogs = new Map();
		EventLogs._instance = this;
    }

    @ReadOnly
    record(event,moduleType){
        if(event.getEventStatus() !== EventStatus.FAILED){
            event.setEventStatus(EventStatus.COMPLETED);
        }
        this._eventLogs.push(event)
        if(this._moduleSessionLogs.has(moduleType)){
            const moduleLogs = this._moduleSessionLogs.get(moduleType)
            moduleLogs.push(event)
            this._moduleSessionLogs.set(moduleType, moduleLogs)
            return;
        }
        this._moduleSessionLogs.set(moduleType,[event])
    }

    @ReadOnly
    getAllEventLogs() {
        return this._eventLogs;
    }

    @ReadOnly
    getAllModuleSessionLogs(){
        return this._moduleSessionLogs
    }

    @ReadOnly
    getModuleSessionLogs(moduleType){
        if(!moduleType) throw new Error("Invalid module type");
        if(this._moduleSessionLogs.has(moduleType)) return this._moduleSessionLogs.get(moduleType)
        return "Module Type Doesnt Exist";
    }

    @ReadOnly
    getEventRecordById(id){
        return this._eventLogs.find(event => event.getEventId() === id);
    }

}