import * as Sentry from "@sentry/node";
import { getOrCreateStore } from "../with-redux-store";
import _ from "lodash";
import LogRocket from 'logrocket'

// severity Level Definations
const severityLevel = {
	1: "fatal",
	2: "error",
	3: "warning",
	4: "info",
	5: "debug"
};

//Custtom Tag Definitions (Module Name):
const moduleNameTag = {
	0: "GENERAL",
	1: "CUSTOMERS",
	2: "JOBS",
	3: "SCHEDUlE_DISPATCH",
	4: "WORKFORCE",
	5: "REPORTS",
	6: "ESTIMATES",
	7: "INVOICES",
	8: "SETTINGS",
	9: "COMPANY_HOLIDAYS",
	10: "OUT_OF_OFFICE",
	11: "CATALOG"
};

//Function to Log Errors to Sentry Dashboard
export const logError = (
	errorMessage = "Error Occured",
	moduleName = 0,
	level = 2
) => {
	//Workaround to get Redux Store outside React Components
	const { user } = getOrCreateStore().getState().userData;

	if (!_.isEmpty(user)) {
		const user_data = {
			id: user.userId,
			tenantId: user.tenantId,
			userType: user.userType,
			username: `${user.firstName} ${user.lastName}`,
			email: user.emailId,
			privileges: user.privileges
		};

		Sentry.configureScope(scope =>
			scope
				.setLevel(severityLevel[level])
				.setUser(user_data)
				.setTag("Module Name", moduleNameTag[moduleName])
		);

		// Attacting Log Rocket video session URL.
		LogRocket.getSessionURL((url) => {
			Sentry.configureScope(scope => {
				scope.setExtra("logRocketSessionURL", url);
			});
		})

		return Sentry.captureException(errorMessage);
	} else {
		Sentry.configureScope(scope =>
			scope
				.setLevel(severityLevel[level])
				.setTag("Module Name", moduleNameTag[moduleName])
		);
		return Sentry.captureException(errorMessage);
	}
};
