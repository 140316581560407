const MenuList = ({isOpen,children,className})  =>{
    return(
        <div className={`menu-list ${className} ${isOpen ? "open-menu" : ""} `}>
            <div className="item-list">
                {children}
            </div>
        </div>
    )
}


export default MenuList;