import React, { useEffect } from "react";
import {
	FaCheckCircle,
	FaInfoCircle,
	FaExclamationTriangle,
	FaBan
} from "react-icons/fa";
import { removeToast } from "../../actions/toasts";
import { useSelector, useDispatch } from "react-redux";

const Toaster = () => {
	const dispatch = useDispatch();
	let toasts = useSelector(state => state.toasts);

	return (
		<ul className="toasts">
			{toasts.map(toast => {
				const { id } = toast;
				return (
					<Toast
						{...toast}
						key={id}
						onDismissClick={() => dispatch(removeToast(id))}
					/>
				);
			})}
		</ul>
	);
};

const Toast = props => {
	useEffect(() => {
		// props.autoClose &&
		setTimeout(() => {
			props.onDismissClick();
		}, 2500);
	}, [props.autoClose]);

	return (
		<li
			onClick={props.onDismissClick}
			className={props.type ? `toast ${props.type}` : "toast"}>
			<p className="toast__content">
				{props.type ? (
					<span className="icon">
						{props.type === "info" ? <FaInfoCircle /> : null}
						{props.type === "success" ? <FaCheckCircle /> : null}
						{props.type === "warning" ? <FaExclamationTriangle /> : null}
						{props.type === "error" ? <FaBan /> : null}
					</span>
				) : null}
				<span>{props.text}</span>
			</p>
			<span className="close-x"></span>
		</li>
	);
};

export default Toaster;
