import { ReadOnly } from "./core-decorators";

export class FunctionHandler {
	constructor({ handler, eventType,handlerName }) {
		if (!handler || !eventType) {
			throw new Error("Function/EventType not registered");
		}
		if (typeof handler !== "function" || typeof eventType !== "string") {
			throw new Error("Parameter passed does not confirm to the Standards");
		}
		this._handler = handler;
		this._eventType = eventType;
		this._handlerName = handlerName || handler.name ;
		if(!this._handlerName){
			throw new Error("'handlerName' is undefined");
		}
	}

	@ReadOnly
	getHandler() {
		return this._handler;
	}

	@ReadOnly
	getEventType() {
		return this._eventType;
	}

	@ReadOnly
	getHandlerName() {
		return this._handlerName;
	}
}

export class MethodHandler {
	constructor({ target, property, eventType }) {
		if (!target || !property || !eventType) {
			throw new Error("Missing required parameters");
		}
		if (
			typeof target !== "object" ||
			typeof target.constructor !== "function" ||
			typeof property !== "string" ||
			typeof eventType !== "string"
		) {
			throw new Error("Parameter passed does not confirm to the Standards");
		}
		this._target = target;
		this._property = property;
		this._eventType = eventType;
	}

	@ReadOnly
	getTargetClass() {
		return this._target;
	}

	@ReadOnly
	getProperty() {
		return this._property;
	}

	@ReadOnly
	getEventType() {
		return this._eventType;
	}
}
