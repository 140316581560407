import {
	QueryHandler,
	PostHandler
} from "../event-driven/core-utils/core-decorators";
import AxiosAPIInterface from "./axios-api-interface";

export default class SkillsAPI extends AxiosAPIInterface {
	constructor() {
		if (SkillsAPI._instance) {
			return SkillsAPI._instance;
		}
		super({ resoucePath: "/skills" });
		SkillsAPI._instance = this;
	}

	@QueryHandler("GET_SKILLS")
	getAll(params) {
		return this.get({ params });
	}

	@PostHandler("ADD_SKILL")
	createSkill({ body }) {
		return this.post({ body });
	}

	@PostHandler("EDIT_SKILL")
	editSkill({ skillId, body }) {
		return this.put({ path: `/${skillId}`, body });
	}

	@PostHandler("DELETE_SKILL")
	deleteSkill({ skillId }) {
		return this.delete({ path: `/${skillId}` });
	}
}
