const MenuItem = ({
	item,
	selected,
	onItemSelection,
	children,
	displaySubOptionsOnHover
}) => {
	if (displaySubOptionsOnHover) {
		return (
			<div className="menu-contents">
				<div
					className={`menu-item show-all-menu-items ${
						selected ? "selected" : ""
					}`}
					onClick={() => onItemSelection(item)}
				>
					{item.label}
				</div>
				{children && (
					<div
						className={`menu-sub-items`}
						onClick={() => onItemSelection(item)}
					>
						{children}
					</div>
				)}
			</div>
		);
	} else {
		return (
			<div
				className={`menu-item ${
					item?.disabled && "disabled-input-box"
				} ${selected ? "selected" : ""}`}
				onClick={() => !item?.disabled && onItemSelection(item)}
			>
				{item.label}
			</div>
		);
	}
};

MenuItem.defaultProps = {
	item: {},
	selected: false
};

export default MenuItem;
