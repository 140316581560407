import {
	PostHandler,
	QueryHandler
} from "../event-driven/core-utils/core-decorators";
import AxiosAPIInterface from "./axios-api-interface";

export default class TeamsAPI extends AxiosAPIInterface {
	constructor() {
		if (TeamsAPI._instance) {
			return TeamsAPI._instance;
		}
		super({ resoucePath: "/teams" });
		TeamsAPI._instance = this;
	}

	@QueryHandler("GET_ALL_TEAMS")
	getAll(params) {
		return this.get({ params });
	}

	@PostHandler("CREATE_TEAM")
	createTeam({ body }) {
		return this.post({ body });
	}

	@PostHandler("UPDATE_TEAM")
	updateTeam({ body, teamId }) {
		return this.put({ body, path: `/${teamId}` });
	}

	@QueryHandler("DELETE_TEAM")
	deleteTeam({ id }) {
		return this.delete({ path: `/${id}` });
	}
}
