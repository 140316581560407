import { EXPORT, CLEAR_EXPORT, EXPORT_STATUS } from "./types";

export function setExportFile(fileDetails) {
	localStorage.setItem("downloadFile", JSON.stringify(fileDetails));
	return {
		payload: fileDetails,
		type: EXPORT
	};
}

export function clearExportFile() {
	localStorage.removeItem("downloadFile");
	return {
		type: CLEAR_EXPORT
	};
}

export function setExportStatus(exportStatus) {
	return {
		payload: exportStatus,
		type: EXPORT_STATUS
	};
}
