import { QueryHandler } from "../event-driven/core-utils/core-decorators";
import AxiosAPIInterface from "./axios-api-interface";

export default class WorkFlowAPI extends AxiosAPIInterface {
	constructor() {
		if (WorkFlowAPI._instance) {
			return WorkFlowAPI._instance;
		}
		super({ resoucePath: "/workerstatus" });
		WorkFlowAPI._instance = this;
	}

	@QueryHandler("GET_WORK_FLOW")
	getWorkflow({ params }) {
		return this.get({ params });
	}

	@QueryHandler("ADD_POOL_WORK_FLOW")
	addPoolWorkFlow({ name }) {
		return this.post({ body: { name } });
	}

	@QueryHandler("UPDATE_POOL_WORK_FLOW")
	updatePoolWorkFlow({ name, id }) {
		return this.patch({ body: { name }, path: `/${id}` });
	}

	@QueryHandler("DELETE_POOL_WORK_FLOW")
	deletePoolWorkFlow({ id }) {
		return this.delete({ path: `/${id}` });
	}
}
