import BaseEventBus from "./base-event-bus";
import { EventBusType } from "./types";

class SettingsEventBus extends BaseEventBus {
	constructor() {
		if (SettingsEventBus._instance) {
			return SettingsEventBus._instance;
		}
		super();
		this._moduleType = "SETTINGS";
		this._eventBusName = EventBusType.SETTINGS;
		SettingsEventBus._instance = this;
	}
}

export default SettingsEventBus;
