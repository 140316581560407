import BaseEventBus from "./base-event-bus";

class WorkforceEventBus extends BaseEventBus {
	constructor() {
		if (WorkforceEventBus._instance) {
			return WorkforceEventBus._instance;
		}
		super();
		this._moduleType = "WORKFORCE";
		this._eventBusName = "WORKFORCE_EVENT_BUS";
		WorkforceEventBus._instance = this;
	}
}

export default WorkforceEventBus;
