import { QueryHandler } from "../event-driven/core-utils/core-decorators";
import AxiosAPIInterface from "./axios-api-interface";

export default class ConfigurationAPI extends AxiosAPIInterface {
	constructor() {
		if (ConfigurationAPI._instance) {
			return ConfigurationAPI._instance;
		}
		super({ resoucePath: "/configurations" });
		ConfigurationAPI._instance = this;
	}

	@QueryHandler("GET_CONFIGURATION_BY_MODULE")
	getConfigurationByModuleType({ moduleId }) {
		return this.get({ params: { moduleType: moduleId } });
	}

	@QueryHandler("GET_CONFIGURATION_BY_USER_MODULE")
	getConfigurationByUserAndModuleType({ userId, moduleId, headers = {} }) {
		return this.get({
			path: `/${userId}`,
			params: { moduleType: moduleId },
			headers
		});
	}

	@QueryHandler("UPDATE_CONFIGURATION_BY_USER_MODULE")
	updateConfigurationByUserAndModuleType({ payload, moduleType }) {
		return this.post({ params: { moduleType }, body: payload });
	}
}
