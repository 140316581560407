import {
	PostHandler,
	QueryHandler
} from "../event-driven/core-utils/core-decorators";
import AxiosAPIInterface from "./axios-api-interface";

export default class JobsAPI extends AxiosAPIInterface {
	constructor() {
		if (JobsAPI._instance) {
			return JobsAPI._instance;
		}
		super({ resoucePath: "/jobs" });
		JobsAPI._instance = this;
	}

	@QueryHandler("GET_JOB_LISTING_DATA")
	async getJobsList({
		select = [],
		offset = 0,
		limit = 10,
		search,
		sort,
		jobStatus,
		invoiceStatus,
		headers = {},
		deleted,
		query,
		recordStatus = ""
	}) {
		if (search) search = search.trim();
		const params = {
			offset,
			limit,
			...(recordStatus && { recordStatus: recordStatus }),
			...query,
			...(select.length !== 0 && { select }),
			...(search && { search }),
			...(sort && { sort }),
			...(jobStatus && { jobStatus }),
			...(invoiceStatus && { invoiceStatus }),
			includeDeleted: deleted ? 1 : 0
		};
		const response = await this.get({ params, headers });
		return {
			...response,
			jobs: response.jobs.map(job => ({ ...job, offset }))
		};
	}

	@QueryHandler("GET_ALL_JOBS")
	getAll(params) {
		return this.get({ params });
	}

	@QueryHandler("GET_JOB_DETAILS")
	getJobDetails({ id, params }) {
		return this.get({ path: `/${id}`, params: params });
	}

	@QueryHandler("JOB_NUMBER_VALIDATION")
	jobNumberValidation({ jobNumber }) {
		return this.get({ path: "exists", params: { jobNumber } });
	}

	@PostHandler("CREATE_JOB")
	createJob({ body, params }) {
		return this.post({ body, params });
	}

	@PostHandler("GENERATE_JOB_FILES_UPLOAD_URL")
	uploadJobFormFiles({ body, params }) {
		return this.post({ body, params, path: `/upload-url` });
	}

	@PostHandler("DELETE_JOB")
	deleteJob(data) {
		return this.delete({ path: `/${data.jobId}`, params: data.params });
	}

	@QueryHandler("GET_JOB_ACTIVITY_LOGS")
	getJobActivityLogs({ id }) {
		return this.get({ path: `${id}/activity?source=1` });
	}

	@QueryHandler("GET_JOBS_LINEITEMS_DETAILS")
	getJobLineItemsDetails({ params }) {
		return this.get({ path: `/line-items`, params: params });
	}
}
