import { PAYMENT_INTEGRATION_DETAILS } from "../actions/types";

const paymentIntegration = (state = {}, action) => {
	switch (action.type) {
		case PAYMENT_INTEGRATION_DETAILS:
			return action.payload;
		default:
			return state;
	}
};

export default paymentIntegration;
