import { QueryHandler } from "../event-driven/core-utils/core-decorators";
import AxiosAPIInterface from "./axios-api-interface";

export const USERS_API_V1 = {
	GET_ALL_USERS: "GET_ALL_USERS"
};
export default class UsersAPIV1 extends AxiosAPIInterface {
	constructor() {
		if (UsersAPIV1._instance) {
			return UsersAPIV1._instance;
		}
		super({ resoucePath: "/v1/users" });
		UsersAPIV1._instance = this;
	}

	@QueryHandler(USERS_API_V1.GET_ALL_USERS)
	getAll(params) {
		return this.get({ params });
	}

	@QueryHandler("GET_USER_LISTING_DATA")
	getUserListingTableData({
		select = [],
		count = true,
		offset = 0,
		limit = 10,
		status,
		search,
		sort,
		headers = {}
	}) {
		const params = {
			count,
			offset,
			limit,
			...(select.length !== 0 && { select }),
			...(status && { userStatus: status }),
			...(search && { search }),
			...(sort && { sort })
		};

		return this.get({ headers, params }).then(response => ({
			...response,
			users: response.users.map(user => ({
				...user,
				offset
			}))
		}));
	}
}
