import { PostHandler } from "../event-driven/core-utils/core-decorators";
import AxiosAPIInterface from "./axios-api-interface";

export default class PreferenceAPI extends AxiosAPIInterface {
	constructor() {
		if (PreferenceAPI._instance) {
			return PreferenceAPI._instance;
		}
		super({ resoucePath: "/preferences" });
		PreferenceAPI._instance = this;
	}

	@PostHandler("SET_PREFERENCE")
	setPreference({ payload }) {
		return this.put({
			path: "/",
			body: payload
		});
	}
}
