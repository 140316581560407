import BaseEventBus from "./base-event-bus";

class EstimateEventBus extends BaseEventBus {
	constructor() {
		if (EstimateEventBus._instance) {
			return EstimateEventBus._instance;
		}
		super();
		this._moduleType = "ESTIMATE";
		this._eventBusName = "ESTIMATE_EVENT_BUS";
		EstimateEventBus._instance = this;
	}
}

export default EstimateEventBus;
