import { Component, Fragment } from "react";

export default class CrossModuleAccess extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	componentDidMount() {}

	componentWillUnmount() {}

	render() {
		return <Fragment></Fragment>;
	}
}
