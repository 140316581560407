import { QueryHandler } from "../event-driven/core-utils/core-decorators";
import AxiosAPIInterface from "./axios-api-interface";
import {
	ADD_ROLE_NAME,
	DELETE_ROLE,
	DELETE_ROLE_USERS,
	GET_ALL_ROLES,
	GET_ROLE_PERMISSIONS,
	GET_ROLE_USERS,
	UPDATE_ROLE_NAME,
	UPDATE_ROLE_PERMISSIONS
} from "./events/roles-events";

export default class RolesAPI extends AxiosAPIInterface {
	constructor() {
		if (RolesAPI._instance) {
			return RolesAPI._instance;
		}
		super({ resoucePath: "/roles" });
		RolesAPI._instance = this;
	}

	@QueryHandler("GET_ROLES")
	getRoles() {
		return this.get({});
	}

	@QueryHandler(GET_ALL_ROLES)
	getAllRoles(params) {
		return this.get({ params });
	}

	@QueryHandler(DELETE_ROLE)
	deleteRole({ id }) {
		return this.delete({ path: `/${id}` });
	}

	@QueryHandler(UPDATE_ROLE_NAME)
	updateRoleName(body) {
		return this.put({ path: `/${body.id}`, body });
	}

	@QueryHandler(ADD_ROLE_NAME)
	addRoleName(body) {
		return this.post({ body });
	}

	@QueryHandler(GET_ROLE_USERS)
	getRoleUsers(id) {
		const path = `/${id}/users`;
		return this.get({ path });
	}

	@QueryHandler(GET_ROLE_PERMISSIONS)
	getRolePermissions({ userId: id }) {
		const path = `/${id}`;
		return this.get({ path });
	}

	@QueryHandler(DELETE_ROLE_USERS)
	deleteRoleUsers({ roleId, body }) {
		const path = `/${roleId}/users`;
		return this.put({ path, body });
	}

	@QueryHandler(UPDATE_ROLE_PERMISSIONS)
	updateRolePrivileges({ roleId, body }) {
		const path = `${roleId}/privileges`;
		return this.put({ path, body });
	}
}
