import { QueryHandler } from "../event-driven/core-utils/core-decorators";
import AxiosAPIInterface from "./axios-api-interface";

export default class CatalogAPI extends AxiosAPIInterface {
	constructor() {
		if (CatalogAPI._instance) {
			return CatalogAPI._instance;
		}
		super({ resoucePath: "/catalog" });
		CatalogAPI._instance = this;
	}

	@QueryHandler("SEARCH_CATALOG_PRODUCTS")
	getAll(params) {
		return this.get({ params, path: "/search" });
	}

	@QueryHandler("GET_CATALOG_PRODUCT")
	getOne(itemId) {
		return this.get({ path: `/items/${itemId}` });
	}
}
