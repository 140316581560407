import { EXPORT, CLEAR_EXPORT } from "../actions/types";

export function downloadFile(state = null, action) {
	const { payload, type } = action;

	switch (type) {
		case EXPORT:
			return payload;
		case CLEAR_EXPORT:
			return null;
		default:
			return state;
	}
}
