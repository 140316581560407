import {
	CLEAR_SCHEDULE_STATE_DATA,
	ADD_SCHEDULE_STATE_DATA
} from "../actions/types";

export function scheduleReducer(state = null, action) {
	const { type, payload } = action;
	switch (type) {
		case CLEAR_SCHEDULE_STATE_DATA:
			return null;
		case ADD_SCHEDULE_STATE_DATA:
			return payload;
		default:
			return state;
	}
}
