import { QueryHandler } from "../event-driven/core-utils/core-decorators";
import AxiosAPIInterface from "./axios-api-interface";

export default class ExportAPI extends AxiosAPIInterface {
	constructor() {
		if (ExportAPI._instance) {
			return ExportAPI._instance;
		}
		super({ resoucePath: "/export" });
		ExportAPI._instance = this;
	}

	@QueryHandler("GET_EXPORT_DATA")
	getExportData({ showMessage, ...params }, exportId) {
		if (exportId) {
			return this.get({ path: `/${exportId}` }).then(result => {
				if (result.fileStatus === "completed") {
					const url = `${process.env.S3_ENDPOINT}/${result.tenantId}/${result.resource}/${result.fileName}`;
					window.open(url, "_self");
				} else if (
					result.fileStatus === "pending" ||
					result.fileStatus === "processing"
				) {
					setTimeout(() => {
						this.getExportData({ showMessage, ...params }, exportId);
					}, 5000);
				}
				showMessage(result.fileStatus);
			});
		} else {
			return this.get({ params }).then(response => {
				if (response && response.id) {
					this.getExportData({ showMessage, ...params }, response.id);
				}
			});
		}
	}
}
