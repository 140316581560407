import {
	CATALOG,
	EDIT_FOLDER,
	PARENT_FOLDER,
	REFRESH_FOLDER,
	SET_ACTIVE_FOLDER,
	UPDATE_FOLDERS
} from "../../actions/types";

const catalogInitialState = {
	activeFolder: {
		refresh: false
	},
	showAddEditFolderModal: false,
	selectedType: null,
	updatedFolders: null
};

export default function folderReducer(state = catalogInitialState, action) {
	const { type, payload } = action;
	switch (type) {
		case CATALOG:
			return { ...state, ...payload };
		case SET_ACTIVE_FOLDER:
			return {
				...state,
				activeFolder: payload
			};
		case REFRESH_FOLDER:
			return {
				...state,
				activeFolder: {
					...state.activeFolder,
					refresh: !state.activeFolder.refresh
				}
			};
		case EDIT_FOLDER:
			return {
				...state,
				editFolder: payload
			};
		case PARENT_FOLDER:
			return {
				...state,
				parentFolders: payload
			};
		case UPDATE_FOLDERS:
			return {
				...state,
				updatedFolders: payload,
				deleteFolder: null
			};
		default:
			return state;
	}
}
