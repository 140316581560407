import { QueryHandler } from "../event-driven/core-utils/core-decorators";
import AxiosAPIInterface from "./axios-api-interface";

export default class CustomerAPI extends AxiosAPIInterface {
	constructor() {
		if (CustomerAPI._instance) {
			return CustomerAPI._instance;
		}
		super({ resoucePath: "/customers" });
		CustomerAPI._instance = this;
	}

	@QueryHandler("GET_CUSTOMER_LISTING_DATA")
	async getCustomersList({
		select = [],
		offset = 0,
		limit = 10,
		search,
		sort,
		headers = {},
		deleted = null,
		archived = null,
		groupIds = null,
		parentIds = null
	}) {
		if (search) search = search.trim();
		const params = {
			offset,
			limit,
			...(select.length !== 0 && { select }),
			...(search && { query: search }),
			...(sort && { sort }),
			...(deleted && { deleted }),
			...(archived && { archived }),
			...(groupIds && { groupIds }),
			...(parentIds && { parentIds })
		};
		const response = await this.get({ params, headers });
		return {
			...response,
			customers: response.customers.map(customer => ({ ...customer, offset }))
		};
	}

	@QueryHandler("GET_CUSTOMERS")
	getCustomers({ offset = 0, limit = 50, query }) {
		return this.get({
			path: "filter",
			params: { offset, limit, query }
		});
	}

	@QueryHandler("SEARCH_CUSTOMERS")
	searchCustomers({ offset = 0, limit = 50, query = "", entityIds = null }) {
		return this.get({
			path: "search",
			params: { offset, limit, query, entityIds }
		});
	}

	@QueryHandler("GET_CUSTOMERS_BY_GROUP_ID")
	getCustomersByGroupId({ groupIds }) {
		return this.get({ path: "filter", params: { entityIds: groupIds } });
	}

	@QueryHandler("GET_LOCATIONS_BY_CUSTOMER_ID")
	getLocationByCustomer({ customerId }) {
		return this.get({ path: `/${customerId}/locations` });
	}

	@QueryHandler("GET_CUSTOMER")
	getCustomerById({ customerId }) {
		return this.get({ path: `/${customerId}` });
	}

	@QueryHandler("GET_CONTACTS_BY_CUSTOMER_ID")
	getContactsByCustomer({ customerId }) {
		return this.get({ path: `/${customerId}/contacts` });
	}

	@QueryHandler("CREATE_CUSTOMER")
	createCustomer({ body }) {
		return this.post({ body });
	}

	@QueryHandler("UPDATE_CUSTOMER")
	updateCustomer({ customerId, body }) {
		return this.put({ path: `/${customerId}`, body });
	}

	@QueryHandler("GET_CONTACTS")
	getContacts({ customerId, limit, offset, search, select }) {
		const query =
			`${limit ? "limit=" + limit : ""}${
				offset !== null ? "&offset=" + offset : ""
			}` +
			`${search ? "&searchQuery=" + search : ""}&count=true` +
			`${select ? "&select=" + select : ""}`;
		return this.get({ path: `/${customerId}/contacts?${query}` });
	}

	@QueryHandler("GET_LOCATIONS")
	getLocations({ customerId, limit, offset, search, select }) {
		const query =
			`${limit ? "limit=" + limit : ""}${
				offset !== null || offset ? "&offset=" + offset : ""
			}` +
			`${search ? "&searchQuery=" + search : ""}&count=true` +
			`${select ? "&select=" + select : ""}`;
		return this.get({ path: `/${customerId}/locations?${query}` });
	}

	@QueryHandler("ADD_CONTACT")
	addContact({ body, customerId }) {
		const path = `${customerId}/contacts?sync=false`;
		return this.post({ path, body });
	}

	@QueryHandler("ADD_LOCATION")
	addLocation({ body, customerId }) {
		const path = `${customerId}/locations?sync=false`;
		return this.post({ path, body });
	}

	@QueryHandler("UPDATE_CONTACT")
	updateContact({ customerId, contactId, values }) {
		const path = `/${customerId}/contacts/${contactId}`;
		return this.put({ path, body: values });
	}

	@QueryHandler("UPDATE_LOCATION")
	updateLocation({ customerId, locationId, values }) {
		const path = `/${customerId}/locations/${locationId}`;
		return this.put({ path, body: values });
	}

	@QueryHandler("UPDATE_PRIMARY_OR_BILLING")
	updatePrimaryOrBilling({ path, id, idKey, key }) {
		return this.put({ path, body: { [idKey]: id, [key]: true } });
	}

	@QueryHandler("DELETE_CUSTOMER")
	deleteCustomer({ path }) {
		return this.delete({ path });
	}

	@QueryHandler("DELETE_LOCATION_OR_CONTACT")
	deleteLocationOrContact({ path }) {
		return this.delete({ path });
	}
}
