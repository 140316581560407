import BaseEventBus from "./base-event-bus";

class CustomerEventBus extends BaseEventBus {
	constructor() {
		if (CustomerEventBus._instance) {
			return CustomerEventBus._instance;
		}
		super();
		this._moduleType = "CUSTOMER";
		this._eventBusName = "CUSTOMER_EVENT_BUS";
		CustomerEventBus._instance = this;
	}
}

export default CustomerEventBus;
