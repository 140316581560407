import { PostHandler } from "../event-driven/core-utils/core-decorators";
import AxiosAPIInterface from "./axios-api-interface";

export default class PaymentTokenAPI extends AxiosAPIInterface {
	constructor() {
		if (PaymentTokenAPI._instance) {
			return PaymentTokenAPI._instance;
		}
		super({ resoucePath: "/v1/jwt-token" });
		PaymentTokenAPI._instance = this;
	}

	@PostHandler("GENERATE_PAYMENT_TOKEN")
	getPaymentToken(body) {
		return this.post({ body });
	}
}
