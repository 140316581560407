import {
	QueryHandler,
	PostHandler
} from "../event-driven/core-utils/core-decorators";
import AxiosAPIInterface from "./axios-api-interface";

export default class HOOAPI extends AxiosAPIInterface {
	constructor() {
		if (HOOAPI._instance) {
			return HOOAPI._instance;
		}
		super({ resoucePath: "/hoo" });
		HOOAPI._instance = this;
	}

	@QueryHandler("GET_HOO_SCHEDULE")
	getHooSchedule({ params }) {
		return this.get({ params });
	}

	@PostHandler("UPDATE_HOO_SCHEDULE")
	updateHooSchedule({ body }) {
		return this.post({ body });
	}
}
