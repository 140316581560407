import { EventStatus } from "../core-utils/constants";
import { ReadOnly } from "../core-utils/core-decorators";
import { FunctionHandler, MethodHandler } from "../core-utils/hanlder-object";
import EventLogs from "../event-log";

// Enhancement - Callback during the progress of the event
export default class BaseEventBus {
	constructor() {
		this._eventBus = new Array();
		this._registeredEventHandlers = new Map();
		this._scheduledEvents = new Map();
		this._eventLogger = new EventLogs();
		this._moduleType = "BASE_BUS";
		this._eventBusName = "";
	}

	*generateMultiple(noOfEvents) {
		let noOfEventsGenerated = 0;
		while (noOfEventsGenerated < noOfEvents) {
			noOfEventsGenerated++;
			yield this._eventBus.shift();
		}
	}

	*generate() {
		yield this._eventBus.shift();
	}

	@ReadOnly
	setEventHanlder(eventType, handler, hanlderName) {
		if (this._registeredEventHandlers.has(eventType)) {
			const handlerArray = this._registeredEventHandlers.get(eventType);
			if (handlerArray.some(object => object.hanlderName === hanlderName))
				return;
			handlerArray.push({ handler, hanlderName });
			this._registeredEventHandlers.set(eventType, handlerArray);
			return;
		}
		this._registeredEventHandlers.set(eventType, [{ handler, hanlderName }]);
	}

	@ReadOnly
	registerEventHandler(registerHandler) {
		if (registerHandler instanceof FunctionHandler) {
			this.setEventHanlder(
				registerHandler.getEventType(),
				registerHandler.getHandler(),
				registerHandler.getHandlerName()
			);
			return;
		}
		if (registerHandler instanceof MethodHandler) {
			const constructorFunc = registerHandler.getTargetClass().constructor;
			const objectInstance = new constructorFunc();
			const hanlder =
				objectInstance[registerHandler.getProperty()].bind(objectInstance);
			this.setEventHanlder(
				registerHandler.getEventType(),
				hanlder,
				registerHandler.getProperty()
			);
			return;
		}
	}

	@ReadOnly
	deregisterEventHandler(deRegisterHandler) {
		if (deRegisterHandler instanceof FunctionHandler) {
			if (this._registeredEventHandlers.has(deRegisterHandler.getEventType())) {
				const handlerArray = this._registeredEventHandlers.get(
					deRegisterHandler.getEventType()
				);
				const filteredArray = handlerArray.filter(
					object => object.hanlderName !== deRegisterHandler.getHandlerName()
				);
				if (filteredArray.length > 0) {
					this._registeredEventHandlers.set(
						deRegisterHandler.getEventType(),
						filteredArray
					);
				} else {
					this._registeredEventHandlers.delete(
						deRegisterHandler.getEventType()
					);
				}
			}
		}
	}

	@ReadOnly
	deregisterAllEventHandlers() {
		this._registeredEventHandlers = new Map();
	}

	@ReadOnly
	deregisterAllEventHandlersForEvent(eventType) {
		this._registeredEventHandlers.delete(eventType);
	}

	@ReadOnly
	emit(event) {
		event.setEventBusName(this._eventBusName);
		this._eventBus.push(event);
		this._react();
	}

	@ReadOnly
	_react() {
		const getEvent = this.generate();
		const event = getEvent.next().value;
		event.setEventStatus(EventStatus.PROCESSING);
		const eventDetails = event.getEventDetails();
		const eventType = event.getEventType();
		try {
			if (this._registeredEventHandlers.has(eventType)) {
				const handlerArray = this._registeredEventHandlers.get(eventType);
				handlerArray.forEach(({ handler }) => handler(eventDetails));
			}
			this._eventLogger.record(event, this._moduleType);
		} catch (error) {
			event.setEventStatus(EventStatus.FAILED);
			this._eventLogger.record(event, this._moduleType);
			throw error;
		}
	}

	@ReadOnly
	query(event) {
		event.setEventBusName(this._eventBusName);
		this._eventBus.push(event);
		return this._query();
	}

	@ReadOnly
	_query() {
		const getEvent = this.generate();
		const event = getEvent.next().value;
		event.setEventStatus(EventStatus.PROCESSING);
		const eventDetails = event.getEventDetails();
		const eventType = event.getEventType();
		try {
			if (this._registeredEventHandlers.has(eventType)) {
				const handlerArray = this._registeredEventHandlers.get(eventType);
				this._eventLogger.record(event, this._moduleType);
				return handlerArray.map(({ handler }) => handler(eventDetails));
			}
		} catch (error) {
			event.setEventStatus(EventStatus.FAILED);
			this._eventLogger.record(event, this._moduleType);
			throw error;
		}
		return [];
	}

	@ReadOnly
	scheduleEmit(event, delay, clearSave = false) {
		const eventType = event.getEventType();
		if (this._scheduledEvents.has(eventType)) {
			const timeOut = this._scheduledEvents.get(eventType)[0];
			clearTimeout(timeOut);
		}
		if (!clearSave) {
			this._scheduledEvents.set(eventType, [
				setTimeout(() => {
					const scheduledEvent = this._scheduledEvents.get(eventType)[1];
					this._scheduledEvents.delete(eventType);
					this.emit(scheduledEvent);
				}, delay),
				event
			]);
		}
	}
}
