import { PostHandler } from "../event-driven/core-utils/core-decorators";
import AxiosAPIInterface from "./axios-api-interface";

export default class UploadS3API extends AxiosAPIInterface {
	constructor() {
		if (UploadS3API._instance) {
			return UploadS3API._instance;
		}
		super({ resoucePath: "/" });
		UploadS3API._instance = this;
	}

	@PostHandler("UPLOAD_JOB_FILES_DATA")
	uploadFiles({ url, payload, config, callbackFn }) {
		config.CancelToken = new this._axios.CancelToken(c => {
			callbackFn(c, payload);
		});
		return this.put({
			path: url,
			body: payload,
			params: config.params,
			config
		});
	}
}
