import {
	PostHandler,
	QueryHandler
} from "../event-driven/core-utils/core-decorators";
import AxiosAPIInterface from "./axios-api-interface";

export default class TenantAPI extends AxiosAPIInterface {
	constructor() {
		if (TenantAPI._instance) {
			return TenantAPI._instance;
		}
		super({ resoucePath: "/tenant" });
		TenantAPI._instance = this;
	}

	@PostHandler("GET_TENANT_DETAILS")
	getTenant({ tenantId }) {
		return this.get({ path: `/${tenantId}` });
	}

	@PostHandler("UPLOAD_TENANT_LOGO")
	uploadTenantLogo({ tenantId, payload }) {
		return this.post({ path: `/${tenantId}/logo`, body: payload });
	}

	@QueryHandler("DELETE_TENANT_LOGO")
	deleteTenantLogo({ tenantId }) {
		return this.delete({ path: `/${tenantId}/logo` });
	}
}
