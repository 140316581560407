import BaseEventBus from "./base-event-bus";
import { EventBusType } from "./types";

class UserEventBus extends BaseEventBus {
	constructor() {
		if (UserEventBus._instance) {
			return UserEventBus._instance;
		}
		super();
		this._moduleType = "USER";
		this._eventBusName = EventBusType.USERS;
		UserEventBus._instance = this;
	}
}

export default UserEventBus;
