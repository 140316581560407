// Move this to Component Folder
import Loader from "./Loader";

const Button = ({
	id="",
	children,
	color,
	underline,
	variant,
	iconPosition,
	className,
	onClick,
	size,
	type,
	style,
	disabled,
	loading
}) => {
	return (
		<button
			id={id}
			type={type}
			className={`button ${variant} ${color} ${
				underline ? "underline" : ""
			} ${size} ${iconPosition} ${className}`}
			style={style}
			onClick={loading ? () => {} : onClick}
			disabled={disabled}>
			{loading && <Loader inline />}
			{children}
		</button>
	);
};

// TODO: Remove all Defaylt Props
Button.defaultProps = {
	color: "primary" | "secondary" | "danger",
	underline: false,
	variant: "primary" | "outline" | "text",
	iconPosition: "left" | "right",
	className: "",
	onClick: () => {},
	size: "medium",
	type: "button",
	style: {},
	disabled: false,
	loading: false
};

export default Button;
