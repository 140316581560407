import { 
	CUSTOM_FIELDS_DATA, 
	TEAM_OPTIONS_DATA, 
	JOBS_INITIAL_DATA,
	ESTIMATE_OPTIONS_DATA
} from "../actions/types";

const initialState = {
	customFieldsData: [],
	teamOptions: [],
	initialStateValues: {},
	estimateOptions: [],
};

export function jobs(state = initialState, action) {
	const { payload, type } = action;
	switch (type) {
		case CUSTOM_FIELDS_DATA:
			return {
				...state, 
				customFieldsData: payload
			};

		case TEAM_OPTIONS_DATA:
			return {
				...state, 
				teamOptions: payload
			};

		case JOBS_INITIAL_DATA:
			return {
				...state, 
				initialStateValues: payload
			};

		case ESTIMATE_OPTIONS_DATA:
			return {
				...state, 
				estimateOptions: payload
			};

		default:
			return state;
	}
}
