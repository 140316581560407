import { Fragment, useEffect, useRef, useState } from "react";
import MenuButton from "./MenuButton";
import MenuList from "./MenuList";
import { FaCaretDown } from "react-icons/fa";
import MenuSubItem from "./MenuSubItem";
import MenuItem from "./MenuItem";

/** options props Structure
 * const options = [
        {
            label: "Menu Item",
            value: "item",
            subOptions: [
                { label: "Sub Menu Item", value: "item" },
            ]
        },
        { label: "Menu Item", value: "item" },
    ];
 * 
*/

const outsideMenuClickHandler = (ref, closeMenu) => {
	useEffect(() => {
		const handleClickOutside = event => {
			if (ref.current && !ref.current.contains(event.target)) {
				closeMenu();
			}
		};
		document.addEventListener("mousedown", handleClickOutside);
		return () => {
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, [ref]);
};

const Menu = ({
	className = "",
	options = [],
	label,
	icon,
	menuPosition,
	onItemSelection,
	onSubItemSelection,
	displaySubOptionsOnHover
}) => {
	const [isOpen, setIsOpen] = useState(false);
	const menuRef = useRef();
	const closeMenu = () => {
		setIsOpen(false);
	};
	outsideMenuClickHandler(menuRef, closeMenu);
	const onItemSelectionHandler = item => {
		setIsOpen(false);
		onItemSelection(item);
	};
	const onSubItemSelectionHandler = (item, subItem) => {
		setIsOpen(false);
		onSubItemSelection(item, subItem);
	};
	return (
		<div className={`menu ${menuPosition} ${className}`} ref={menuRef}>
			<MenuButton
				isOpen={isOpen}
				label={label}
				onClick={() => setIsOpen(!isOpen)}
				icon={icon}
			/>
			<MenuList
				isOpen={isOpen}
				className={`${displaySubOptionsOnHover ? "overide-overflow" : ""}`}>
				{options &&
					options.map((item, index) => (
						<Fragment key={index}>
							{displaySubOptionsOnHover ? (
								<MenuItem
									item={item}
									onItemSelection={onItemSelectionHandler}
									displaySubOptionsOnHover={displaySubOptionsOnHover}>
									{item.subOptions ? (
										<Fragment>
											{(item.subOptions || []).map((subItem, subIndex) => (
												<MenuSubItem
													key={subIndex}
													item={item}
													subItem={subItem}
													onSubItemSelection={onSubItemSelectionHandler}
												/>
											))}
										</Fragment>
									) : null}
								</MenuItem>
							) : (
								<Fragment>
									<MenuItem
										item={item}
										onItemSelection={onItemSelectionHandler}
									/>
									{item.subOptions &&
										item.subOptions.map((subItem, subIndex) => (
											<MenuSubItem
												key={subIndex}
												item={item}
												subItem={subItem}
											/>
										))}
								</Fragment>
							)}
						</Fragment>
					))}
			</MenuList>
		</div>
	);
};

// TODO: Remove all Defaylt Props
Menu.defaultProps = {
	options: [],
	label: "Menu",
	icons: <FaCaretDown />,
	menuPosition: "left" | "center" | "right",
	onItemSelection: () => {},
	onSubItemSelection: () => {}
};

export default Menu;
