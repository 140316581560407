import { QueryHandler } from "../event-driven/core-utils/core-decorators";
import AxiosAPIInterface from "./axios-api-interface";

export default class TaxesAPI extends AxiosAPIInterface {
	constructor() {
		if (TaxesAPI._instance) {
			return TaxesAPI._instance;
		}
		super({ resoucePath: "/catalog" });
		TaxesAPI._instance = this;
	}

	@QueryHandler("GET_ALL_TAXES")
	getAll(params) {
		return this.get({ path: `/taxes`, params });
	}

	@QueryHandler("DELETE_TAX")
	deleteTax(id) {
		return this.delete({ path: `/tax/${id}` });
	}

	@QueryHandler("ADD_TAX")
	addTax(payload) {
		return this.post({ path: "/tax", body: payload });
	}
	@QueryHandler("EDIT_TAX")
	editTax({ id, body }) {
		return this.put({ path: `/tax/${id}`, body });
	}
}
