import {
	PostHandler,
	QueryHandler
} from "../event-driven/core-utils/core-decorators";
import AxiosAPIInterface from "./axios-api-interface";

export const categoryAPIEventTypes = {
	UPDATE_CATEGORY: "UPDATE_CATEGORY",
	ADD_CATEGORY: "ADD_CATEGORY",
	GET_ALL_CATEGORIES: "GET_ALL_CATEGORIES",
	GET_CATEGORIES_BY_ENTITY_ID: "GET_CATEGORIES_BY_ENTITY_ID",
	VALIDATE_CATEGORY_MAPPING: "VALIDATE_CATEGORY_MAPPING",
	DELETE_CATEGORY: "DELETE_CATEGORY",
	ENTITY_MAPPING: "ENTITY_MAPPING",
	UPDATE_GROUP_CATEGORY: "UPDATE_GROUP_CATEGORY",
	ADD_GROUP_CATEGORY: "ADD_GROUP_CATEGORY"
};

export default class ServiceTypeAPI extends AxiosAPIInterface {
	constructor() {
		if (ServiceTypeAPI._instance) {
			return ServiceTypeAPI._instance;
		}
		super({ resoucePath: "/serviceTypes" });
		ServiceTypeAPI._instance = this;
	}
	@QueryHandler(categoryAPIEventTypes.GET_ALL_CATEGORIES)
	getAllCategories({ params }) {
		return this.get({ path: `/`, params });
	}
	@QueryHandler(categoryAPIEventTypes.GET_CATEGORIES_BY_ENTITY_ID)
	getAllCategoriesByEntityId({ params }) {
		return this.get({ path: `/assignment`, params });
	}

	@QueryHandler("GET_JOB_CATEGORIES_DROPDOWN")
	getJobCategories({ params }) {
		return this.get({ path: `/dropdown`, params });
	}

	@QueryHandler(categoryAPIEventTypes.VALIDATE_CATEGORY_MAPPING)
	validateJobCategory({ params, id }) {
		return this.get({ path: `/${id}/validation`, params });
	}

	@QueryHandler(categoryAPIEventTypes.DELETE_CATEGORY)
	deleteCategory({ params }) {
		const data = { mappingId: params.mappingId };
		return this.delete({ path: `/${params.id}`, params: data });
	}

	@PostHandler(categoryAPIEventTypes.ADD_CATEGORY)
	addCategory({ payload }) {
		return this.post({ path: `/`, body: payload });
	}
	@PostHandler(categoryAPIEventTypes.UPDATE_CATEGORY)
	updateCategory({ payload, params }) {
		return this.patch({
			path: `/${payload.id}`,
			body: payload,
			params: params
		});
	}

	@QueryHandler(categoryAPIEventTypes.ENTITY_MAPPING)
	entityMapping() {
		return this.get({ path: "/entity-mapping" });
	}

	@PostHandler(categoryAPIEventTypes.UPDATE_GROUP_CATEGORY)
	updateGroupCategory({ payload }) {
		return this.put({
			path: `/assignment`,
			body: payload
		});
	}

	@PostHandler(categoryAPIEventTypes.ADD_GROUP_CATEGORY)
	addGroupCategory({ payload }) {
		return this.post({
			path: `/assignment`,
			body: payload
		});
	}
}
