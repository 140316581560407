import GroupsAPI from "./group-api";
import InvoiceAPI from "./invoice-api";
import LoginAPI from "./login-api";
import CustomerAPI from "./customer-api";
import TagsAPI from "./tags-api";
import TaxesAPI from "./taxes-api";
import JobsAPI from "./jobs-api";
import CatalogAPI from "./catalog-api";
import ConfigurationAPI from "./configuration-api";
import EstimateAPI from "./estimate-api";
import TeamsAPI from "./teams-api";
import UsersAPI from "./users-api";
import ServiceTypeAPI from "./serviceType-api";
import CustomFieldsAPI from "./customFields-api";
import ExportAPI from "./export-api";
import JobStatusAPI from "./jobStatus-api";
import UploadS3API from "./upload-s3-api";
import PaymentAPI from "./payment-api";
import TenantAPI from "./tenant-api";
import ExternalSystemIntegrationAPI from "./externalIntegration-api";
import PaymentTokenAPI from "./payment-token-api";
import JobsAPIV1 from "./jobs-api-v1";
import PreferenceAPI from "./preference-api";
import PreferencesAPI from "./preferences-api";
import WorkforceAPI from "./workforce-api";
import FieldWorkFlowAPI from "./fieldWorkFlow-api";
import WorkFlowAPI from "./workflow-api";
import UsersAPIV1 from "./users-api-v1";
import RecurringJobsAPI from "./recurring-jobs-api";
import OutOfOfficeAPI from "./outOfOffice-api";
import RolesAPI from "./roles-api";
import SkillsAPI from "./skills-api";
import RatesAPI from "./rates-api";
import HOOAPI from "./hoo-api";
import PdfJobSummaryAPI from "./pdf-job-symmary-api";

export const apiRegistery = {
	invoiceApi: new InvoiceAPI(),
	loginApi: new LoginAPI(),
	groupsApi: new GroupsAPI(),
	customerApi: new CustomerAPI(),
	tagsApi: new TagsAPI(),
	taxesAPI: new TaxesAPI(),
	jobsAPI: new JobsAPI(),
	jobsAPIV1: new JobsAPIV1(),
	jobStatusAPI: new JobStatusAPI(),
	catalogAPI: new CatalogAPI(),
	configurationAPI: new ConfigurationAPI(),
	estimateAPI: new EstimateAPI(),
	teamsAPI: new TeamsAPI(),
	usersAPI: new UsersAPI(),
	serviceTypeAPI: new ServiceTypeAPI(),
	customFieldsAPI: new CustomFieldsAPI(),
	exportAPI: new ExportAPI(),
	uploadS3API: new UploadS3API(),
	paymentAPI: new PaymentAPI(),
	tenantAPI: new TenantAPI(),
	externalSystemIntegration: new ExternalSystemIntegrationAPI(),
	paymentTokenAPI: new PaymentTokenAPI(),
	preferenceAPI: new PreferenceAPI(),
	workforceAPI: new WorkforceAPI(),
	preferencesAPI: new PreferencesAPI(),
	FieldWorkFlowAPI: new FieldWorkFlowAPI(),
	usersAPIV1: new UsersAPIV1(),
	recurringJobsAPI: new RecurringJobsAPI(),
	WorkFlowAPI: new WorkFlowAPI(),
	RolesAPI: new RolesAPI(),
	OutOfOfficeAPI: new OutOfOfficeAPI(),
	skillsAPI: new SkillsAPI(),
	ratesAPI: new RatesAPI(),
	UsersAPIV1: new UsersAPIV1(),
	HOOAPI: new HOOAPI(),
	pdfJobSummaryAPI: new PdfJobSummaryAPI()
};
