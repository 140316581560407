import BaseEventBus from "./base-event-bus";


class InvoiceEventBus extends BaseEventBus{
	constructor() {
		if (InvoiceEventBus._instance) {
			return InvoiceEventBus._instance;
		}
		super();
		this._moduleType = "INVOICE";
		this._eventBusName = "INVOICE_EVENT_BUS";
		InvoiceEventBus._instance = this;
	}
}

export default InvoiceEventBus;
