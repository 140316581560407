import { PostHandler } from "../event-driven/core-utils/core-decorators";
import AxiosAPIInterface from "./axios-api-interface";

export default class JobsAPIV1 extends AxiosAPIInterface {
	constructor() {
		if (JobsAPIV1._instance) {
			return JobsAPIV1._instance;
		}
		super({ resoucePath: "/v1/jobs" });
		JobsAPIV1._instance = this;
	}

	@PostHandler("EDIT_JOB")
	editJob({ body, jobId, params }) {
		return this.put({ path: `${jobId}`, body, params });
	}
}
