import { QueryHandler } from "../event-driven/core-utils/core-decorators";
import AxiosAPIInterface from "./axios-api-interface";

export default class PdfJobSummaryAPI extends AxiosAPIInterface {
	constructor() {
		if (PdfJobSummaryAPI._instance) {
			return PdfJobSummaryAPI._instance;
		}
		super({ resoucePath: "/pdf-job-summary" });
		PdfJobSummaryAPI._instance = this;
	}

	@QueryHandler("PRINT_JOB_SUMMARY")
	getJobSummary({ jobId }) {
		return this.get({ path: `/${jobId}` });
	}
}
