import Menu from "./Menu";
import { useSelector } from "react-redux";
import { useRouter } from "next/router";
import { Fragment } from "react";

const EasyLinkButton = ({ jobName }) => {
	const { privileges } = useSelector(state => state.userData.user);
	const router = useRouter();
	const getMenuOptions = () => {
		return [
			...(privileges.includes("JOB_CREATE")
				? [{ label: jobName, value: "JOB" }]
				: []),
			...(privileges.includes("ESTIMATE_CREATE")
				? [{ label: "Estimates", value: "ESTIMATE" }]
				: []),
			...(privileges.includes("INVOICE_CREATE")
				? [{ label: "Invoices", value: "INVOICE" }]
				: [])
		];
	};
	const onItemSelectionHandler = ({ value }) => {
		switch (value) {
			case "JOB":
				router.push("/jobs/new");
				break;
			case "ESTIMATE":
				router.push("/estimates/new");
				break;
			case "INVOICE":
				router.push("/invoices/new");
				break;
			default:
				break;
		}
	};

	return (
		<Fragment>
			{getMenuOptions().length !== 0 && (
				<div className="new-styles easy-link-button">
					<Menu
						label="New"
						className="easy-link-menu"
						options={getMenuOptions()}
						menuPosition="right"
						onItemSelection={onItemSelectionHandler}
					/>
				</div>
			)}
		</Fragment>
	);
};

export default EasyLinkButton;
