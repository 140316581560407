import { USER } from "../actions/types";

const initialState = {
	user: {}
};

 const authReducer =  (state = initialState, action) => {
	switch (action.type) {
		case USER:
			return Object.assign({}, state, { user: action.payload });
		default:
			return state;
	}
};
export default authReducer;