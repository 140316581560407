import { INTEGRATION_STATUS } from "../actions/types";

 const integrationStatusReducer =  (state = {}, action) => {
	switch (action.type) {
		case INTEGRATION_STATUS:
			return  action.payload;
		default:
			return state;
	}
};


export default integrationStatusReducer;