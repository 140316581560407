import {
	PostHandler,
	QueryHandler
} from "../event-driven/core-utils/core-decorators";
import AxiosAPIInterface from "./axios-api-interface";

export default class OutOfOfficeAPI extends AxiosAPIInterface {
	constructor() {
		if (OutOfOfficeAPI._instance) {
			return OutOfOfficeAPI._instance;
		}
		super({ resoucePath: "/out-of-office" });
		OutOfOfficeAPI._instance = this;
	}

	@QueryHandler("GET_WORKFORCE_OUT_OF_OFFICE_LISTING_DATA")
	getAll(params) {
		return this.get({ params });
	}

	@PostHandler("CREATE_OOO_REQUEST")
	createOooRequest(body) {
		return this.post({ body });
	}

	@PostHandler("UPDATE_OOO_REQUEST")
	updateOooRequest({ body, id }) {
		return this.put({ path: `/${id}`, body });
	}

	@QueryHandler("DELETE_OOO_REQUEST")
	deleteOooRequest(id) {
		return this.delete({ path: `/${id}` });
	}

	@PostHandler("VALIDATE_OOO_REQUEST")
	ValidateOooRequest({ body }) {
		return this.post({ path: "/validate", body });
	}
}
