import {
	PostHandler,
	QueryHandler
} from "../event-driven/core-utils/core-decorators";
import AxiosAPIInterface from "./axios-api-interface";

export default class PaymentAPI extends AxiosAPIInterface {
	constructor() {
		if (PaymentAPI._instance) {
			return PaymentAPI._instance;
		}
		super({ resoucePath: "/v1/payments" });
		PaymentAPI._instance = this;
	}

	@PostHandler("INVOICE_MANUAL_PAYMENT")
	manualPayment({ invoiceId, paymentDetails }) {
		return this.post({ path: `/manual/${invoiceId}`, body: paymentDetails });
	}

	@QueryHandler("GET_PAYMENT_METHODS")
	getAll({ customerId }) {
		return this.get({ path: `/customer/${customerId}/payment-methods` });
	}

	@QueryHandler("REGISTER_CUSTOMER_TO_STAX")
	registerCustomer({ customerId }) {
		return this.post({
			path: `/customer`,
			body: { customerIds: [customerId] }
		});
	}

	@QueryHandler("GET_PAYMENT_TRANSACTIONS_HISTORY")
	getTransactionsHistory({ customerId }) {
		return this.get({
			path: `/customer/${customerId}/transactions`
		});
	}

	@PostHandler("ENROLL_CUSTOMER_TO_STAX")
	enrollCustomerToStax({ params, body }) {
		return this.post({
			path: `/enrollment`,
			body,
			params
		});
	}

	@QueryHandler("GET_PAYMENT_INTEGRATION_DETAILS")
	getPaymentIntegrationDetails() {
		return this.get({ path: "/integration-details" });
	}

	@QueryHandler("GET_PAYMENT_ACTIVITY_LOG")
	getPaymentActivityLog() {
		return this.get({ path: `/enrollment/activity` });
	}
}
