import BaseEventBus from "./base-event-bus";
import { EventBusType } from "./types";

class ScheduleEventBus extends BaseEventBus {
	constructor() {
		if (ScheduleEventBus._instance) {
			return ScheduleEventBus._instance;
		}
		super();
		this._moduleType = "SCHEDULE";
		this._eventBusName = EventBusType.SCHEDULE;
		ScheduleEventBus._instance = this;
	}
}

export default ScheduleEventBus;