import { UPDATE_RECURRING_JOB_STATUS } from "../actions/types";

const initialState = {
	recurringJobExists: false
};
export function settingReducer(state = initialState, action) {
	switch (action.type) {
		case "SETTING":
			return action.payload;

		case UPDATE_RECURRING_JOB_STATUS:
			return { ...state, recurringJobExists: action.payload };
		default:
			return state;
	}
}
