import {
	PostHandler,
	QueryHandler
} from "../event-driven/core-utils/core-decorators";
import AxiosAPIInterface from "./axios-api-interface";

export const tagsAPIEventTypes = {
	GET_TAGS_BY_MODULE: "GET_TAGS_BY_MODULE",
	UPDATE_TAGS: "UPDATE_TAGS",
	UPDATE_TAGS_BY_MODULE: "UPDATE_TAGS_BY_MODULE",
	CREATE_TAGS: "CREATE_TAGS",
	DELETE_TAG: "DELETE_TAG",
	REACTIVATE_TAG: "REACTIVATE_TAG"
};

export default class TagsAPI extends AxiosAPIInterface {
	constructor() {
		if (TagsAPI._instance) {
			return TagsAPI._instance;
		}
		super({ resoucePath: "/tags" });
		TagsAPI._instance = this;
	}

	@QueryHandler(tagsAPIEventTypes.GET_TAGS_BY_MODULE)
	getTagsByModule({
		moduleId,
		select,
		recordStatus,
		useCount,
		includeInactive
	}) {
		return this.get({
			params: {
				module: moduleId,
				select,
				recordStatus,
				useCount,
				includeInactive
			}
		});
	}

	@QueryHandler(tagsAPIEventTypes.UPDATE_TAGS_BY_MODULE)
	updateTagsBysModule({ moduleId, id, tags }) {
		const path = `/module/${moduleId}/${id}`;
		return this.put({ path, body: tags });
	}

	@PostHandler(tagsAPIEventTypes.UPDATE_TAGS)
	updateTag({ id, payload }) {
		const path = `/${id}`;
		return this.put({ path, body: payload });
	}

	@PostHandler(tagsAPIEventTypes.REACTIVATE_TAG)
	reactivateTag({ id, payload }) {
		const path = `/activate/${id}`;
		return this.put({ path, body: payload });
	}

	@PostHandler(tagsAPIEventTypes.CREATE_TAGS)
	addTag({ body }) {
		return this.post({ body });
	}

	@QueryHandler(tagsAPIEventTypes.DELETE_TAG)
	deleteTag({ tagId, payload }) {
		const path = `/${tagId}`;
		return this.delete({ path, body: payload });
	}
}
