import axios from "axios";
import { parseCookies } from "nookies";
const cookies = parseCookies();

const tokenKey = `access_token`;
const tokenValue = cookies["token"] ? cookies["token"] : "";

//Ref :  https://github.com/axios/axios#config-defaults
//Ref : https://github.com/zeit/next.js/blob/canary/examples/with-env-from-next-config-js/next.config.js
axios.defaults.baseURL = process.env.FSM_REST_ENDPOINT; //Env Variable Check next.config.js file
axios.defaults.headers.common[tokenKey] = `bearer ` + tokenValue;

export default axios;
