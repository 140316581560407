import { QueryHandler } from "../event-driven/core-utils/core-decorators";
import AxiosAPIInterface from "./axios-api-interface";

export default class WorkforceAPI extends AxiosAPIInterface {
	constructor() {
		if (WorkforceAPI._instance) {
			return WorkforceAPI._instance;
		}
		super({ resoucePath: "/worker-time" });
		WorkforceAPI._instance = this;
	}

	@QueryHandler("GET_WORKFORCE_TIMECARD_LISTING_DATA")
	getWorkforceListingTableData({
		select = [],
		count = true,
		offset = 0,
		limit = 10,
		search,
		sort,
		from,
		to,
		headers = {}
	}) {
		const params = {
			count,
			offset,
			limit,
			from,
			to,
			...(select.length !== 0 && { select }),
			...(search && { search }),
			...(sort && { sort })
		};

		return this.get({ params, headers, path: `/time-cards` }).then(
			response => ({
				...response,
				workers: response.workers.map(worker => ({
					...worker,
					offset,
					from,
					to
				}))
			})
		);
	}

	@QueryHandler("GET_TIMECARD_USER_LISTING")
	getTimecardUserListingData({ workerId, eventDate }) {
		const params = {
			eventDate
		};

		return this.get({ params, path: `/time-cards/${workerId}` });
	}

	@QueryHandler("GET_WORKER_JOB_TIMESTAMP_DATA")
	getWorkerJobTimestampData({ body }) {
		return this.post({ body });
	}

	@QueryHandler("UPDATE_WORKER_JOB_TIMECARD")
	updateWorkerJobTimecard({ body, timeCard }) {
		return this.put({ path: `/correction?timeCard=${timeCard}`, body });
	}
}
