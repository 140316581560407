import { EXPORT_STATUS } from "../actions/types";

export function exportStatus(state = false, action) {
	const { payload, type } = action;

	switch (type) {
		case EXPORT_STATUS:
			return payload;
		default:
			return state;
	}
}
