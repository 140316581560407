import { QueryHandler } from "../event-driven/core-utils/core-decorators";
import AxiosAPIInterface from "./axios-api-interface";

export default class JobStatusAPI extends AxiosAPIInterface {
	constructor() {
		if (JobStatusAPI._instance) {
			return JobStatusAPI._instance;
		}
		super({ resoucePath: "/job-status" });
		JobStatusAPI._instance = this;
	}

	@QueryHandler("GET_ALL_JOB_STATUS")
	getAll(params) {
		return this.get({ params });
	}

	@QueryHandler("GET_JOB_STATUS_BY_MAPPING_ID")
	getJobStatusByMappingID(params) {
		return this.get({ path: "/assignment", params });
	}

	@QueryHandler("ADD_JOB_STATUS")
	addJobStatus({ params, body }) {
		return this.post({ params, body });
	}

	@QueryHandler("UPDATE_JOB_STATUS")
	updateJobStatus({ id, body }) {
		return this.put({ path: `/${id}`, body });
	}

	@QueryHandler("DELETE_JOB_STATUS")
	deleteJobStatus({ id, params }) {
		return this.delete({ params, path: `/${id}` });
	}

	@QueryHandler("ADD_SUB_STATUS")
	addSubStatus({ jobstatusId, params, body }) {
		const path = `/${jobstatusId}/sub-status`;
		return this.post({ path, params, body });
	}

	@QueryHandler("DELETE_SUB_STATUS")
	deleteSubStatus({ jobstatusId, params, id }) {
		const path = `/${jobstatusId}/sub-status/${id}`;
		return this.delete({ path, params });
	}

	@QueryHandler("UPDATE_SUB_STATUS")
	updateSubStatus({ id, jobstatusId, body }) {
		const path = `/${jobstatusId}/sub-status/${id}`;
		return this.put({ path, body });
	}

	@QueryHandler("UPDATE_DISPLAY_ORDER")
	updateDisplayOrder({ body }) {
		return this.put({ path: `/order`, body });
	}

	@QueryHandler("GET_MAPPED_JOB_STATUS")
	getMappedJobstatus({ params }) {
		return this.get({ path: "/assignment", params });
	}

	@QueryHandler("GET_JOB_STATUS_ENTITY_MAPPING")
	getMappedEntities({ params }) {
		return this.get({ path: "/entity-mapping", params });
	}

	@QueryHandler("UPDATE_MAPPED_JOB_STATUS")
	updateMappedJobstatus(body) {
		return this.put({ path: "assignment", body });
	}

	@QueryHandler("ADD_MAPPED_JOB_STATUS")
	addMappedJobstatus(body) {
		return this.post({ path: "assignment", body });
	}
}
