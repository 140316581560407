import BaseEventBus from "./base-event-bus";

class PaymentEventBus extends BaseEventBus {
	constructor() {
		if (PaymentEventBus._instance) {
			return PaymentEventBus._instance;
		}
		super();
		this._moduleType = "PAYMENT";
		this._eventBusName = "PAYMENT_EVENT_BUS";
		PaymentEventBus._instance = this;
	}
}

export default PaymentEventBus;
