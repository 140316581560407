import { SET_USER_PREFERENCES, USER_PREFERENCES } from "../actions/types";

const userPreferencesReducer = (state = [], action) => {
	switch (action.type) {
		case USER_PREFERENCES:
			return action.payload;
		case SET_USER_PREFERENCES: {
			const newPreference = state.map(pref => {
				action.payload.forEach(updateObj => {
					if (pref.preferenceKey === updateObj.preferenceKey) {
						pref.value = updateObj.value;
					}
				});

				return pref;
			});

			return newPreference;
		}

		default:
			return state;
	}
};

export default userPreferencesReducer;
