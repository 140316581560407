import ApiEventBus from "./event-bus/api-event-bus";
import EstimateEventBus from "./event-bus/estimate-event-bus";
import InvoiceEventBus from "./event-bus/invoice-event-bus";
import JobEventBus from "./event-bus/job-event-bus";
import CustomerEventBus from "./event-bus/customer-event-bus";
import PaymentEventBus from "./event-bus/payment-event-bus";
import SettingsEventBus from "./event-bus/settings-event-bus";
import WorkforceEventBus from "./event-bus/workforce-event-bus";
import UserEventBus from "./event-bus/user-event-bus";
import ScheduleEventBus from "./event-bus/schedule-event-bus";

const registeredEventBus = {
	API_EVENT_BUS: ApiEventBus,
	INVOICE_EVENT_BUS: InvoiceEventBus,
	ESTIMATE_EVENT_BUS: EstimateEventBus,
	JOB_EVENT_BUS: JobEventBus,
	CUSTOMER_EVENT_BUS: CustomerEventBus,
	PAYMENT_EVENT_BUS: PaymentEventBus,
	WORKFORCE_EVENT_BUS: WorkforceEventBus,
	SETTINGS_EVENT_BUS: SettingsEventBus,
	USER_EVENT_BUS: UserEventBus,
	SCHEDULE_EVENT_BUS: ScheduleEventBus
};

export default class EventBusManager {
	constructor(eventBusName) {
		if (!registeredEventBus[eventBusName]) return null;
		return new registeredEventBus[eventBusName]();
	}
}
