import React, { useState, Fragment, useEffect } from "react";
import { FaCaretDown } from "react-icons/fa";
import Link from "next/link";
import { useRouter } from "next/router";
import _ from "lodash";
export const List = ({
	verticle,
	children,
	isDropActive,
	dropDownList,
	className
}) => {
	const router = useRouter();
	useEffect(() => {
		if (dropDownList) {
			if (children[0]) {
				const decideActive = [];
				children.map((child, i) => {
					if (
						child.props &&
						child.props.children &&
						child.props.children.props &&
						child.props.children.props.href &&
						router
					) {
						if (
							child.props &&
							child.props.children &&
							child.props.children.props &&
							child.props.children.props.href === router.pathname
						) {
							decideActive.push("true");
						} else {
							decideActive.push("false");
						}
					}
				});
				isDropActive(decideActive);
			} else {
				const decideActive = [];
				if (children.props.href && router) {
					if (children.props.href === router.pathname) {
						decideActive.push("true");
					} else {
						decideActive.push("false");
					}
				}
				isDropActive(decideActive);
			}
		}
	}, [children]);
	return (
		<ul
			className={`list ${className ? className : ""} ${
				verticle ? "list--verticleList" : " "
			}`}>
			{children}
		</ul>
	);
};
export const ListItem = ({ children, href }) => {
	const router = useRouter();
	return (
		<li
			className={`listItem ${
				href && router && href === router.pathname ? "active" : ""
			}`}>
			{href ? (
				<Link href={href}>
					<a className="listLabel">{children}</a>
				</Link>
			) : (
				<span className="listLabel">{children}</span>
			)}
		</li>
	);
};
export const DropList = ({ children, label }) => {
	const [dropActive, setDropActive] = useState(false);
	const [dropClick, setDropClick] = useState(false);
	const setDropToggle = booleanArray => {
		if (!dropClick) {
			if (booleanArray.includes("true")) {
				setDropActive(true);
			} else {
				setDropActive(false);
			}
		}
	};
	const newChildren = React.Children.map(children, (child, index) => {
		return React.cloneElement(child, {
			isDropActive: val => setDropToggle(val),
			dropDownList: true
		});
	});
	return (
		<li className="listItem listItem--DropListItem">
			<span
				onClick={() => {
					setDropActive(!dropActive);
					setDropClick(true);
				}}
				className={`listLabel ${
					dropActive ? "listLabel--Opened" : "listLabel--Closed"
				}`}>
				<span>{label}</span>
				<FaCaretDown />
			</span>
			<Fragment>{newChildren}</Fragment>
		</li>
	);
};
