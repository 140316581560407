import { createStore, applyMiddleware } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import thunkMiddleware from "redux-thunk";

import rootReducer from "./reducers/";
import LogRocket from 'logrocket'

export const appInitialState = {};

export function initializeStore(initialState = appInitialState) {
	return createStore(
		rootReducer,
		initialState,
		composeWithDevTools(applyMiddleware(thunkMiddleware, LogRocket.reduxMiddleware()))
	);
}
