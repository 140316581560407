import { QueryHandler } from "../event-driven/core-utils/core-decorators";
import AxiosAPIInterface from "./axios-api-interface";

export default class RatesAPI extends AxiosAPIInterface {
	constructor() {
		if (RatesAPI._instance) {
			return RatesAPI._instance;
		}
		super({ resoucePath: "/rates" });
		RatesAPI._instance = this;
	}

	@QueryHandler("GET_RATES")
	getAll(params) {
		return this.get({ params });
	}

	@QueryHandler("DELETE_RATE")
	deleteRate(params) {
		return this.delete({ params });
	}

	@QueryHandler("ADD_RATE")
	addRate(payload) {
		return this.post({ body: payload });
	}
	@QueryHandler("EDIT_RATE")
	editTax({ id, body }) {
		return this.put({ path: `/${id}`, body });
	}

	@QueryHandler("UPDATE_MULTIPLE_GROUPS")
	handleMultipleGroups({ body }) {
		return this.post({ path: "/upsert-many", body });
	}

	@QueryHandler("OVERRIDDEN_DEFINATIONS")
	overriddenDefinitions({ params }) {
		return this.get({ path: "/overridden-definitions", params });
	}

	@QueryHandler("ALREADY_MAPPED_GROUPS")
	alreadyMappedGroup({ params }) {
		return this.get({ path: "/groups", params });
	}

	@QueryHandler("OVERRIDE_RATE")
	handleOverrideGroup({ id, param }) {
		return this.put({ path: `/${id}`, param });
	}
}
