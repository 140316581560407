import EventBusManager from "../event-bus-manger";
import BaseEventBus from "../event-bus/base-event-bus";
import { MethodHandler } from "./hanlder-object";

// TODO: Code Clean up & Refractoring
export function EventBus(eventBusName) {
	return function classDecorator() {
		return class NewEventBus extends BaseEventBus {
			constructor() {
				if (NewEventBus._instance) {
					return NewEventBus._instance;
				}
				super();
				this._eventBusName = eventBusName;
				NewEventBus._instance = this;
			}
		};
	};
}

export function QueryHandler() {
	const eventType = arguments[0];
	const eventBusManager = new EventBusManager("API_EVENT_BUS")
	return function decorator(target, property, descriptor) {
		// Keep copy of the Original Method
		const method = descriptor.value;

		// Create new method for overwrite
		const decoratedQueryMethod = function () {
			return method.call(this, ...arguments);
		};
		eventBusManager.registerEventHandler(new MethodHandler({target,property,eventType}))

		// Overwrite method with decoratedMethod
		descriptor.value = decoratedQueryMethod;

		// Return the Descriptor for the overwrite to take effect
		return descriptor;
	};
}

export function PostHandler() {
	const eventType = arguments[0];
	const eventBusManager = new EventBusManager("API_EVENT_BUS")
	return function decorator(target, property, descriptor) {
		// Keep copy of the Original Method
		const method = descriptor.value;

		// Create new method for overwrite
		const decoratedQueryMethod = function () {
			return method.call(this, ...arguments);
		};
		eventBusManager.registerEventHandler(new MethodHandler({target,property,eventType}))

		// Overwrite method with decoratedMethod
		descriptor.value = decoratedQueryMethod;

		// Return the Descriptor for the overwrite to take effect
		return descriptor;
	};
}

export function EventHandler() {
	const eventType = arguments[0];
	const eventBusName = arguments[1];
	const eventBusManager = new EventBusManager(eventBusName)
	return function decorator(target, property, descriptor) {

		const method = descriptor.value;

		const decoratedEventMethod = function (args) {
			return method.call(this, args);
		};

		eventBusManager.registerEventHandler(new MethodHandler({target,property,eventType}))

		descriptor.value = decoratedEventMethod;
		return descriptor;
	};
}

export function ReadOnly(target, property, descriptor) {
	// The Property is made Read Only.
	descriptor.writable = false;
	return descriptor;
}
