import { CATALOG_STATS, SEARCH_TOGGLE } from "../../actions/types";

const initialState = {
	searchToggle: false,
	productStats: {
		catalogType: 1,
		itemsCount: 0,
		activeLowInventoryIds: [],
		inactiveLowInventoryIds: [],
		activeOutOfStockItems: 0,
		inactiveOutOfStockItems: 0,
		totalActive: 0,
		totalDeleted: 0,
		totalInactive: 0
	},
	serviceStats: {
		catalogType: 2,
		itemsCount: 0,
		activeLowInventoryIds: [],
		inactiveLowInventoryIds: [],
		activeOutOfStockItems: 0,
		totalActive: 0,
		totalDeleted: 0,
		totalInactive: 0
	},
	assetStats: {
		catalogType: 3,
		itemsCount: 0,
		activeOutOfStockItems: 0,
		totalActive: 0,
		totalDeleted: 0,
		totalInactive: 0
	}
};

export default function catalogRootReducer(state = initialState, action) {
	const { type, payload } = action;
	switch (type) {
		case CATALOG_STATS: {
			const catalogEntityArr = payload || [];
			const productStats = catalogEntityArr.filter(
				entity => entity.catalogType === 1
			);
			const serviceStats = catalogEntityArr.filter(
				entity => entity.catalogType === 2
			);
			return {
				...state,
				productStats: productStats[0] || state.productStats,
				serviceStats: serviceStats[0] || state.serviceStats
			};
		}
		case SEARCH_TOGGLE: {
			return {
				...state,
				searchToggle: !state.searchToggle
			};
		}
		default:
			return state;
	}
}
