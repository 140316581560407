import { useEffect } from "react";
import axios from "../config/Axios";

import { useSelector, useDispatch } from "react-redux";
import { clearExportFile, setExportStatus } from "../actions/export";
import { addToast } from "../actions/toasts";
import { logError } from "../config/SentryConfig";

const executionTimeOut = 20 * 60 * 1000; // 20 minutes in milliseconds

const DownloadFile = () => {
	let refTimer;
	let executionStartTime;
	const S3_URL = process.env.S3_ENDPOINT;
	const downloadFile = useSelector(state => state.downloadFile);
	const dispatch = useDispatch();

	const downLoadExportedFile = fileObject => {
		const url = `${S3_URL}/${fileObject.tenantId}/${fileObject.moduleName}/${fileObject.downloadFileName}`;
		window.location.href = url;
		dispatch(clearExportFile());
		dispatch(
			addToast({
				text: "File is downloaded",
				type: "success"
			})
		);
	};

	const isTimeOutDownload = () =>
		executionStartTime && new Date() - executionStartTime > executionTimeOut;

	const checkFIleCreatedInS3 = fileObject => {
		if (isTimeOutDownload()) {
			dispatch(
				addToast({
					text: "Oops! Something went wrong please try again",
					type: "error"
				})
			);
			clearTimeout(refTimer);
			return;
		}

		axios
			.get(`/export/${fileObject.fileId}`)
			.then(resp => {
				if (resp.data.data.fileStatus === "completed") {
					if (!resp.data.data.fileName) {
						dispatch(clearExportFile());
						dispatch(
							addToast({
								text: "Oops! Download failed, Try again",
								type: "error"
							})
						);
					} else {
						fileObject.downloadFileName = resp.data.data.fileName;
						downLoadExportedFile(fileObject);
					}
					clearTimeout(refTimer);
				} else if (resp.data.data.fileStatus === "noData") {
					dispatch(clearExportFile());
					dispatch(
						addToast({
							text: "There is no data to export",
							type: "warning"
						})
					);
					clearTimeout(refTimer);
				} else if (resp.data.data.fileStatus === "failed") {
					dispatch(clearExportFile());
					dispatch(
						addToast({
							text: "Oops! Download failed, Try again",
							type: "error"
						})
					);
					clearTimeout(refTimer);
				}
				dispatch(setExportStatus(true));
				/* resetting the state after a successful EXPORT_STATUS dispatch
        to ensure that the next EXPORT_STATUS dispatch is considered a state change */
				dispatch(setExportStatus(false));
			})
			.catch(err => {
				logError(err, 0);
				dispatch(
					addToast({
						text: "Oops! Something went wrong please try again",
						type: "error"
					})
				);
				clearTimeout(refTimer);
			});
	};

	useEffect(() => {
		let fileObject = downloadFile;
		if (!fileObject) {
			fileObject = JSON.parse(localStorage.getItem("downloadFile"));
		}
		if (fileObject) {
			executionStartTime = new Date();
			checkFIleCreatedInS3(fileObject);
			refTimer = setInterval(() => {
				checkFIleCreatedInS3(fileObject);
			}, 2000);
			return () => clearTimeout(refTimer);
		}
		return () => clearTimeout(refTimer);
	}, [downloadFile]);

	return null;
};

export default DownloadFile;
