import { QueryHandler } from "../event-driven/core-utils/core-decorators";
import AxiosAPIInterface from "./axios-api-interface";

export default class FieldWorkFlowAPI extends AxiosAPIInterface {
	constructor() {
		if (FieldWorkFlowAPI._instance) {
			return FieldWorkFlowAPI._instance;
		}
		super({ resoucePath: "/fieldworkflow" });
		FieldWorkFlowAPI._instance = this;
	}

	@QueryHandler("GET_FIELD_WORK_FLOW")
	getFieldWorkFlow({ params }) {
		return this.get({ params });
	}

	@QueryHandler("ADD_FIELD_WORK_FLOW")
	addFieldWorkFlow(body) {
		return this.post({ body });
	}

	@QueryHandler("UPDATE_FIELD_WORK_FLOW")
	updateFieldWorkFlow(body) {
		return this.put({ body });
	}

	@QueryHandler("GET_ENTITY_MAPPING")
	getEntityMapping({ params }) {
		return this.get({ path: "/entity-mapping", params });
	}
}
