import {
	PostHandler,
	QueryHandler
} from "../event-driven/core-utils/core-decorators";
import AxiosAPIInterface from "./axios-api-interface";

export default class EstimateAPI extends AxiosAPIInterface {
	constructor() {
		if (EstimateAPI._instance) {
			return EstimateAPI._instance;
		}
		super({ resoucePath: "/v1/estimates" });
		EstimateAPI._instance = this;
	}

	@QueryHandler("GET_ESTIMATE_LISTING_DATA")
	getEstimateListingTableData({
		recordStatus = 0,
		select = [],
		count = true,
		offset = 0,
		limit = 10,
		status,
		search,
		sort,
		headers = {}
	}) {
		const params = {
			count,
			offset,
			limit,
			...(recordStatus && { recordStatus }),
			...(select.length !== 0 && { select }),
			...(status && { status }),
			...(search && { search }),
			...(sort && { sort })
		};
		return this.get({ params, headers }).then(response => ({
			...response,
			estimates: response.estimates.map(estimate => ({ ...estimate, offset }))
		}));
	}

	@PostHandler("CREATE_ESTIMATE")
	createEstimate({ body }) {
		return this.post({ body });
	}

	@PostHandler("EDIT_ESTIMATE")
	editEstimate({ body, estimateId }) {
		return this.patch({ body, path: `/${estimateId}` });
	}

	@QueryHandler("GET_ESTIMATE_ACTIVITY_LOGS")
	getEstimateActivityLogs({ id }) {
		return this.get({ path: `${id}/activity` });
	}

	@QueryHandler("GET_ESTIMATE_BY_ID")
	getEstimate({ estimateId }) {
		return this.get({ path: `/${estimateId}` });
	}

	@QueryHandler("CHECK_ESTIMATE_NUMBER")
	checkEstimateNumber(estimateNumber) {
		return this.get({
			path: `/exists`,
			params: { estimateNumber }
		}).then(response => response.availability);
	}

	@PostHandler("CHANGE_ESTIMATE_STATUS")
	changeInvoiceStatus({ status, estimateId }) {
		return this.put({ path: `/${estimateId}/status`, body: status });
	}

	@PostHandler("SEND_ESTIMATE_TO_CUSTOMER")
	sendEstimateToCustomer(estimateId) {
		return this.post({ path: `/${estimateId}/send` });
	}

	@QueryHandler("DELETE_ESTIMATE")
	deleteEstimate(estimateId) {
		return this.delete({ config: { data: { estimateIds: [estimateId] } } });
	}

	@PostHandler("DOWNLOAD_ESTIMATE")
	downloadEstimate(estimateId) {
		return this.post({ path: `/${estimateId}/download` }).then(({ URL }) =>
			window.open(URL, "_self")
		);
	}
}
