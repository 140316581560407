import React from "react";
import Link from "next/link";
import _ from "lodash";
import { useSelector } from "react-redux";
import { GiHamburgerMenu } from "react-icons/gi";
import { useRouter } from "next/router";

import ThemeColor from "../config/theme";
import { List, ListItem } from "./deprecatedReusable/List";
import "@reach/menu-button/styles.css";
import {
	MenuButton,
	MenuList,
	MenuItem,
	Menu,
	MenuPopover
} from "@reach/menu-button";
import EasyLinkButton from "./uiComponents/EasyLinkButton";
import { getUserPreferences } from "../helpers/util/preferencesUtil";

const Navbar = () => {
	const userPreferences = useSelector(state => state.UserPreferences);

	const userData = useSelector(state => state.userData.user);
	const { privileges } = userData;

	if (_.isEmpty(userPreferences) || _.isEmpty(userData)) return null;

	const [showSettingsV1] = getUserPreferences(userPreferences, [
		"SETTINGS_V1_ON"
	]);

	const preferredJobLabel = userPreferences.find(
		preference => preference.preferenceKey === "JOB_LABEL"
	);

	const workforceManagementConfiguration = userPreferences.find(
		preference => preference.preferenceKey === "TIME_CARD"
	).value;

	const utilizeSkillsConfiguration = userPreferences.find(
		preference => preference.preferenceKey === "UTILIZE_SKILLSETS"
	).value;

	const router = useRouter();

	const isWorkForce =
		privileges.includes("TIME_MANAGEMENT_MANAGE") ||
		privileges.includes("OUT_OF_OFFICE_VIEW") ||
		privileges.includes("USER_VIEW");

	const NavMenu = ({ title, children, routes = [] }) => (
		<li
			className={`nav-menu new-menu-styles ${
				router && routes.includes(router.pathname) && "active"
			}`}>
			<div className="nav-menu-title">{title}</div>

			<ul className="nav-menu-list">{children}</ul>
		</li>
	);

	const NavMenuItem = ({ href, privilege, children }) =>
		privileges.includes(privilege) && (
			<li className={router && router.pathname === href ? "active" : ""}>
				<Link href={href}>
					<a>{children}</a>
				</Link>
			</li>
		);

	return (
		<div id="navbar" style={{ background: ThemeColor.az_gray_6 }}>
			<button className="hamburger-menu" type="button">
				<Menu className="left">
					<MenuButton>
						<GiHamburgerMenu />
					</MenuButton>
					<MenuPopover>
						<MenuList className="navigation-menu-list">
							{privileges.includes("CUSTOMER_VIEW") && (
								<MenuItem
									className={`navigation-menu-item ${
										router && router.pathname === "/" ? "active" : ""
									}`}
									onSelect={() => null}>
									<a href="/">Customers</a>
								</MenuItem>
							)}

							{privileges.includes("JOB_VIEW") && (
								<MenuItem
									className={`navigation-menu-item ${
										router && router.pathname === "/jobs" ? "active" : ""
									}`}
									onSelect={() => null}>
									<a href="/jobs">
										{preferredJobLabel && preferredJobLabel.value}
									</a>
								</MenuItem>
							)}
							{privileges.includes("S_D_VIEW") && (
								<MenuItem
									className={`navigation-menu-item ${
										router && router.pathname === "/schedule-dispatch"
											? "active"
											: ""
									}`}
									onSelect={() => null}>
									<a href="/schedule-dispatch">Scheduling</a>
								</MenuItem>
							)}
							{privileges.includes("TIME_MANAGEMENT_MANAGE") && (
								<MenuItem
									className={`navigation-menu-item ${
										router && router.pathname === "/workforce/time-management"
											? "active"
											: ""
									}`}
									onSelect={() => null}>
									<a href="/workforce/time-management">Time Management</a>
								</MenuItem>
							)}
							{privileges.includes("OUT_OF_OFFICE_VIEW") && (
								<MenuItem
									className={`navigation-menu-item ${
										router && router.pathname === "/workforce/out-of-office"
											? "active"
											: ""
									}`}
									onSelect={() => null}>
									<a href="/workforce/out-of-office">Out Of Office</a>
								</MenuItem>
							)}
							{workforceManagementConfiguration === "Advanced" &&
								privileges.includes("TIME_MANAGEMENT_MANAGE") && (
									<MenuItem
										className={`navigation-menu-item ${
											router && router.pathname === "/workforce/timecard"
												? "active"
												: ""
										}`}
										onSelect={() => null}>
										<a href="/workforce/timecard">Timecard</a>
									</MenuItem>
								)}
							{privileges.includes("USER_VIEW") && (
								<MenuItem
									className={`navigation-menu-item ${
										router && router.pathname === "/workforce/workers"
											? "active"
											: ""
									}`}
									onSelect={() => null}>
									<a href="/workforce/workers">Workers</a>
								</MenuItem>
							)}
							{privileges.includes("RATES_VIEW") && (
								<MenuItem
									className={`navigation-menu-item ${
										router && router.pathname === "/workforce/rates"
											? "active"
											: ""
									}`}
									onSelect={() => null}>
									<a href="/workforce/rates">Rates</a>
								</MenuItem>
							)}
							{privileges.includes("SKILLSETS_VIEW") && (
								<MenuItem
									className={`navigation-menu-item ${
										router && router.pathname === "/workforce/skills"
											? "active"
											: ""
									}`}
									onSelect={() => null}>
									<a href="/workforce/skills">Skills</a>
								</MenuItem>
							)}
							{privileges.includes("TEAM_VIEW") && (
								<MenuItem
									className={`navigation-menu-item ${
										router && router.pathname === "/workforce/teams"
											? "active"
											: ""
									}`}
									onSelect={() => null}>
									<a href="/workforce/teams">Teams</a>
								</MenuItem>
							)}
							{privileges.includes("ESTIMATE_VIEW") && (
								<MenuItem
									className={`navigation-menu-item ${
										router && router.pathname === "/estimates" ? "active" : ""
									}`}
									onSelect={() => null}>
									<a href="/estimates">Estimates</a>
								</MenuItem>
							)}
							{privileges.includes("INVOICE_VIEW") && (
								<MenuItem
									className={`navigation-menu-item ${
										router && router.pathname === "/invoices" ? "active" : ""
									}`}
									onSelect={() => null}>
									<a href="/invoices">Invoices</a>
								</MenuItem>
							)}
							{privileges.includes("REPORT_VIEW") && (
								<MenuItem
									className={`navigation-menu-item ${
										router && router.pathname === "/reports" ? "active" : ""
									}`}
									onSelect={() => null}>
									<a href="/reports">Reports</a>
								</MenuItem>
							)}
							{(privileges.includes("ACCOUNT_SETTINGS_VIEW") ||
								privileges.includes("CUSTOM_FIELDS_VIEW") ||
								privileges.includes("CUSTOMER_SETTINGS_VIEW") ||
								privileges.includes("ESTIMATE_SETTINGS_VIEW") ||
								privileges.includes("INVOICE_SETTINGS_VIEW") ||
								privileges.includes("GROUP_SETTINGS_VIEW") ||
								privileges.includes("GROUP_VIEW") ||
								privileges.includes("JOB_SETTINGS_VIEW") ||
								privileges.includes("SERVICE_TYPE_VIEW") ||
								privileges.includes("DATA_MIGRATION_VIEW") ||
								privileges.includes("JOB_STATUS_VIEW") ||
								privileges.includes("WORKER_STATUS_VIEW") ||
								privileges.includes("TAG_VIEW") ||
								privileges.includes("TAX_VIEW") ||
								privileges.includes("WORKFORCE_VIEW") ||
								privileges.includes("ROLE_VIEW") ||
								privileges.includes("TEAM_VIEW")) && (
								<MenuItem
									className={`navigation-menu-item ${
										router && router.pathname === "/account-settings"
											? "active"
											: ""
									}`}
									onSelect={() => null}>
									<a href="/account-settings">Settings</a>
								</MenuItem>
							)}
						</MenuList>
					</MenuPopover>
				</Menu>
			</button>
			<List verticle className="navigation">
				{privileges.includes("CUSTOMER_VIEW") && (
					<ListItem href="/">Customer</ListItem>
				)}
				{privileges.includes("ESTIMATE_VIEW", "INVOICE_VIEW") ? (
					<NavMenu title="WORK" routes={["/jobs", "/estimates", "/invoices"]}>
						{privileges.includes("JOB_VIEW") && (
							<NavMenuItem privilege="JOB_VIEW" href="/jobs">
								{preferredJobLabel && preferredJobLabel.value}
							</NavMenuItem>
						)}
						{privileges.includes("ESTIMATE_VIEW") && (
							<NavMenuItem privilege="ESTIMATE_VIEW" href="/estimates">
								Estimates
							</NavMenuItem>
						)}
						{privileges.includes("INVOICE_VIEW") && (
							<NavMenuItem privilege="INVOICE_VIEW" href="/invoices">
								Invoices
							</NavMenuItem>
						)}
					</NavMenu>
				) : privileges.includes("JOB_VIEW") ? (
					<ListItem href="/jobs">
						{preferredJobLabel && preferredJobLabel.value}
					</ListItem>
				) : (
					" "
				)}

				{privileges.includes("S_D_VIEW") && (
					<ListItem href="/schedule-dispatch">Scheduling</ListItem>
				)}
				{privileges.includes("REPORT_VIEW") && (
					<ListItem href="/reports">Reports</ListItem>
				)}
				{isWorkForce && (
					<NavMenu
						title="WORKFORCE"
						routes={[
							"/workforce/time-management",
							"/workforce/out-of-office",
							"/workforce/timecard",
							"/workforce/workers",
							"/workforce/teams",
							"/workforce/rates",
							"/workforce/skills"
						]}>
						<NavMenuItem
							privilege="TIME_MANAGEMENT_MANAGE"
							href="/workforce/time-management">
							Time Management
						</NavMenuItem>
						<NavMenuItem
							privilege="OUT_OF_OFFICE_VIEW"
							href="/workforce/out-of-office">
							Out Of Office
						</NavMenuItem>
						{workforceManagementConfiguration === "Advanced" && (
							<NavMenuItem
								privilege="TIME_MANAGEMENT_MANAGE"
								href="/workforce/timecard">
								Timecard
							</NavMenuItem>
						)}
						<NavMenuItem privilege="TEAM_VIEW" href="/workforce/teams">
							Teams
						</NavMenuItem>
						<NavMenuItem privilege="USER_VIEW" href="/workforce/workers">
							Workers
						</NavMenuItem>
						<NavMenuItem privilege="RATES_VIEW" href="/workforce/rates">
							Rates
						</NavMenuItem>
						{utilizeSkillsConfiguration === "Yes" && (
							<NavMenuItem privilege="SKILLSETS_VIEW" href="/workforce/skills">
								Skills
							</NavMenuItem>
						)}
					</NavMenu>
				)}

				{privileges.includes("CATALOG_VIEW") && (
					<ListItem href="/catalog">Catalog</ListItem>
				)}

				{(privileges.includes("ACCOUNT_SETTINGS_VIEW") ||
					privileges.includes("CUSTOM_FIELDS_VIEW") ||
					privileges.includes("CUSTOMER_SETTINGS_VIEW") ||
					privileges.includes("ESTIMATE_SETTINGS_VIEW") ||
					privileges.includes("INVOICE_SETTINGS_VIEW") ||
					privileges.includes("GROUP_SETTINGS_VIEW") ||
					privileges.includes("GROUP_VIEW") ||
					privileges.includes("JOB_SETTINGS_VIEW") ||
					privileges.includes("SERVICE_TYPE_VIEW") ||
					privileges.includes("DATA_MIGRATION_VIEW") ||
					privileges.includes("JOB_STATUS_VIEW") ||
					privileges.includes("WORKER_STATUS_VIEW") ||
					privileges.includes("TAG_VIEW") ||
					privileges.includes("TAX_VIEW") ||
					privileges.includes("WORKFORCE_VIEW") ||
					privileges.includes("ROLE_VIEW") ||
					privileges.includes("TEAM_VIEW")) && (
					<ListItem href="/account-settings">Settings</ListItem>
				)}
				{/* // TODO: rewrite this logic when settings left menu is implemented */}
				{showSettingsV1 === "Yes" && (
					<ListItem href="/settings/organisations">Settings(V1)</ListItem>
				)}
				<EasyLinkButton
					jobName={preferredJobLabel && preferredJobLabel.value}
				/>
			</List>
		</div>
	);
};

export default Navbar;
