import {
	PostHandler,
	QueryHandler
} from "../event-driven/core-utils/core-decorators";
import AxiosAPIInterface from "./axios-api-interface";

export default class InvoiceAPI extends AxiosAPIInterface {
	constructor() {
		if (InvoiceAPI._instance) {
			return InvoiceAPI._instance;
		}
		super({ resoucePath: "/v1/invoices" });
		InvoiceAPI._instance = this;
	}

	@PostHandler("CREATE_INVOICE")
	createInvoice({ body, params }) {
		return this.post({ body, params });
	}

	@PostHandler("EDIT_INVOICE")
	editInvoice({ body, params, invoiceId }) {
		return this.put({ body, params, path: `/${invoiceId}` });
	}

	@QueryHandler("GET_INVOICE_BY_ID")
	getById({ invoiceId }) {
		return this.get({ path: `/${invoiceId}` });
	}

	@QueryHandler("GET_ALL_INVOICES")
	getAll() {
		return this.get({});
	}

	@QueryHandler("GET_INVOICE_LISTING_DATA")
	getInvoiceListingTableData({
		recordStatus = 0,
		select = [],
		count = true,
		offset = 0,
		limit = 10,
		status,
		search,
		sort,
		headers = {}
	}) {
		const params = {
			count,
			offset,
			limit,
			...(recordStatus && { recordStatus }),
			...(select.length !== 0 && { select }),
			...(status && { status }),
			...(search && { search }),
			...(sort && { sort })
		};
		return this.get({ params, headers }).then(response => ({
			...response,
			invoices: response.invoices.map(invoice => ({ ...invoice, offset }))
		}));
	}

	@PostHandler("CHANGE_INVOICE_STATUS")
	changeInvoiceStatus({ status, invoiceId }) {
		return this.put({ path: `/${invoiceId}/status`, body: status });
	}

	@QueryHandler("GET_INVOICE_ACTIVITY_LOGS")
	getInvoiceActivityLogs({ id }) {
		return this.get({ path: `${id}/activity` });
	}

	@QueryHandler("CHECK_INVOICE_NUMBER")
	checkInvoiceNumber(invoiceNumber) {
		return this.get({
			path: `/field`,
			params: { name: "invoiceNumber", value: invoiceNumber }
		}).then(response => {
			if (Object.keys(response).length === 0) {
				return true;
			}
			return false;
		});
	}

	@PostHandler("SEND_INVOICE_EMAIL_TO_CUSTOMER")
	sendInvoiceEmailToCustomer(invoiceId) {
		return this.post({ path: `/${invoiceId}/send` });
	}

	@QueryHandler("DELETE_INVOICE")
	deleteInvoice(invoiceId) {
		return this.delete({ config: { data: { invoiceIds: [invoiceId] } } });
	}

	@PostHandler("DOWNLOAD_INVOICE")
	downloadInvoice(invoiceId) {
		return this.post({ path: `/${invoiceId}/download` }).then(({ URL }) =>
			window.open(URL, "_self")
		);
	}
}
