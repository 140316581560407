import React from 'react'
import LogRocket from 'logrocket'
import { useSelector } from 'react-redux';
import { isEmpty } from 'lodash'

const Rocket = () => {
	const userData = useSelector(state => state.userData.user);
    const settings = useSelector(state => state.UserPreferences);

    if (typeof window !== 'undefined' && !isEmpty(settings) && !isEmpty(userData)) {
        const isEnableLogRocket = settings.find((setting) => setting.preferenceKey === "ENABLE_VIDEO_LOGS")
        if (isEnableLogRocket && isEnableLogRocket.value === 'Yes'){
            LogRocket.init(process.env.LOG_ROCKET_PROJECT_KEY)
            LogRocket.identify(userData.userId,{
                ...userData,
                name: userData.firstName + ' ' + userData.lastName,
                email: userData.emailId
            })
        }
    }

    return (<></>)
}

export default React.memo(Rocket)