const MenuSubItem = ({item,subItem,selected,onSubItemSelection}) =>{
    return (
        <div className={`menu-sub-item ${selected?"selected":""}`} onClick={()=>onSubItemSelection(item,subItem)}>
            {subItem.label}
        </div>
    )
}

MenuSubItem.defaultProps = {
    subItem:{},
    selected:false
}

export default MenuSubItem;