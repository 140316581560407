import {
	QueryHandler,
	PostHandler
} from "../event-driven/core-utils/core-decorators";
import AxiosAPIInterface from "./axios-api-interface";

export default class UsersAPI extends AxiosAPIInterface {
	constructor() {
		if (UsersAPI._instance) {
			return UsersAPI._instance;
		}
		super({ resoucePath: "/users" });
		UsersAPI._instance = this;
	}

	@QueryHandler("GET_ALL_WORKERS")
	getAll(params) {
		return this.get({ params });
	}

	@QueryHandler("GET_USER_BY_ID")
	getUserById({ userId, status }) {
		const params = {
			status
		};
		return this.get({ params, path: `/${userId}` });
	}

	@PostHandler("CREATE_USER")
	createUser({ body }) {
		return this.post({ body });
	}

	@PostHandler("EDIT_USER")
	editUser({ body, userId }) {
		return this.put({ body, path: `/${userId}` });
	}

	@QueryHandler("DELETE_USER")
	deleteUser({ userId, emailId, userName, userData }) {
		const params = {
			byUser: userData.userId,
			emailId: emailId,
			userName: userName
		};
		return this.delete({ params, path: `/${userId}` });
	}

	@QueryHandler("GET_LINK_GENERATED_DATE")
	getLinkGeneratedDate({ userId }) {
		return this.get({ path: `/${userId}/link-generated-date` });
	}

	@QueryHandler("GET_USERNAME_BY_EMAILID")
	getUserNameByEmailId({ emailId }) {
		return this.get({ path: `/${emailId}/userNames` });
	}

	@PostHandler("RESEND_PASSWORD_EMAIL")
	resendPasswordEmail({ userId }) {
		return this.put({ path: `/${userId}/resend-link` });
	}

	@QueryHandler("GET_USER_PRIVILEGES")
	getUserPriviliges({ userId }) {
		return this.get({ path: `/${userId}/privileges` });
	}

	@PostHandler("UPDATE_USER_PRIVILEGES")
	updateUserPriviliges({ userId, data }) {
		return this.put({ body: data, path: `/${userId}/privileges` });
	}
}
