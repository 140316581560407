import {
	CANCEL_SELECTED_PRODUCTS,
	SELECT_ALL_PRODUCTS,
	SELECT_PRODUCT_ITEM,
	SET_PRODUCT_ITEMS,
	SORT_AND_FILTERS
} from "../../actions/types";

const productInitialState = {
	selectedProductItems: [],
	isAllProductsSelected: false,
	displayClearSelection: false,
	productItems: [],
	excludeIds: [],
	sortAndFilter: {
		sort: null,
		filter: {
			brand: [],
			unitPrice: { from: "", to: "" },
			retailPrice: { from: "", to: "" },
			tags: [],
			filterApply: false
		}
	}
};

export default function productReducer(state = productInitialState, action) {
	const { type, payload } = action;
	switch (type) {
		case SELECT_PRODUCT_ITEM: {
			let newIdList = [];
			let newItemsCount = state.selectedItemCount;
			let newExcludeIds = state.excludeIds;
			let displayClearSelection = state.displayClearSelection;

			const newProductItems = state.productItems.map(product => {
				if (product.id == payload) {
					if (product.checked) {
						newItemsCount--;
						if (state.isAllProductsSelected) {
							displayClearSelection = true;
							if (state.excludeIds.indexOf(product.id) == -1)
								newExcludeIds.push(product.id);
						}
					} else {
						newItemsCount++;
						if (state.isAllProductsSelected) {
							newExcludeIds = state.excludeIds.filter(id => payload !== id);
						}
					}
					product.checked = !product.checked;
				}
				return product;
			});
			const isItemSelected = state.selectedProductItems.filter(
				id => id === payload
			);

			if (isItemSelected.length === 0) {
				newIdList = state.selectedProductItems.concat(payload);
			} else {
				newIdList = state.selectedProductItems.filter(item => item !== payload);
			}

			return {
				...state,
				productItems: newProductItems,
				selectedProductItems: newIdList,
				selectedItemCount: newItemsCount,
				excludeIds: newExcludeIds,
				isAllProductsSelected:
					newIdList.length === 0 ? false : state.isAllProductsSelected,
				displayClearSelection:
					newItemsCount === 0 || newExcludeIds.length === 0
						? false
						: displayClearSelection
			};
		}
		case CANCEL_SELECTED_PRODUCTS: {
			const cancelledProductItems = state.productItems.map(product => {
				if (Object.prototype.hasOwnProperty.call(product, "checked")) {
					delete product.checked;
				}
				return product;
			});
			return {
				...state,
				selectedProductItems: [],
				productItems: cancelledProductItems,
				isAllProductsSelected: false,
				selectedItemCount: 0,
				displayClearSelection: false,
				excludeIds: []
			};
		}

		case SET_PRODUCT_ITEMS: {
			let newProducts = [];
			let selectedItemCount = state.selectedItemCount;
			const itemListForSelection = [];

			if (state.isAllProductsSelected) {
				newProducts = payload.map(product => {
					if (product.recordStatus !== 1) {
						itemListForSelection.push(product.id);
						product.checked = true;
					}
					return product;
				});
			} else {
				if (state.selectedProductItems.length > 0) {
					const newPayload = payload.map(item => {
						if (state.selectedProductItems.indexOf(item.id) > -1) {
							itemListForSelection.push(item.id);
							item.checked = true;
						}
						return item;
					});
					newProducts = newPayload;
				} else {
					newProducts = payload;
					selectedItemCount = 0;
				}
			}
			return {
				...state,
				productItems: newProducts,
				selectedProductItems: itemListForSelection,
				selectedItemCount
			};
		}

		case SELECT_ALL_PRODUCTS: {
			const itemListForSelection = [];
			const checkedProductItems = state.productItems.map(product => {
				if (product.recordStatus !== 1) {
					itemListForSelection.push(product.id);
					product.checked = true;
				}
				return product;
			});
			return {
				...state,
				productItems: checkedProductItems,
				selectedProductItems: itemListForSelection,
				isAllProductsSelected: true,
				selectedItemCount: payload,
				displayClearSelection: false,
				excludeIds: []
			};
		}
		case SORT_AND_FILTERS:
			return {
				...state,
				sortAndFilter: payload
			};
		default:
			return state;
	}
}
