import BaseEventBus from "./base-event-bus";


class JobEventBus extends BaseEventBus{
	constructor() {
		if (JobEventBus._instance) {
			return JobEventBus._instance;
		}
		super();
		this._moduleType = "JOB";
		this._eventBusName = "JOB_EVENT_BUS";
		JobEventBus._instance = this;
	}
}

export default JobEventBus;