import { combineReducers } from "redux";
import catalogRootReducer from "./catalog/catalogRootReducer";
import folderReducer from "./catalog/folderReducer";
import productReducer from "./catalog/productReducer";
import serviceReducer from "./catalog/serviceReducer";


const catalogReducer = combineReducers({
	root: catalogRootReducer,
	folder: folderReducer,
	product: productReducer,
	service: serviceReducer
});

export default catalogReducer;
