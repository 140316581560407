import { EventStatus } from "../core-utils/constants";
import { ReadOnly } from "../core-utils/core-decorators";
import BaseEventBus from "./base-event-bus";


class ApiEventBus extends BaseEventBus{
	constructor() {
		if (ApiEventBus._instance) {
			return ApiEventBus._instance;
		}
		super();
		this._moduleType = "API";
		this._eventBusName = "API_EVENT_BUS";
		ApiEventBus._instance = this;
	}

	@ReadOnly
	emit(){
		throw new Error("'emit' Method is Unsupported in ApiEventBus, Use Query,ScatterGather or Post eventEmitter")
	}

	@ReadOnly
	post(event){
		event.setEventBusName(this._eventBusName);
		this._eventBus.push(event);
		return this._handleApiEvent()
	}

	@ReadOnly
	query(event){
		event.setEventBusName(this._eventBusName);
		this._eventBus.push(event);
		return this._handleApiEvent()
	}

	@ReadOnly
	_handleApiEvent(){
		const getEvent = this.generate()
		const event = getEvent.next().value
		event.setEventStatus(EventStatus.PROCESSING);
		const eventDetails = event.getEventDetails();
		const eventType = event.getEventType();
		try{
			if(this._registeredEventHandlers.has(eventType)){
				const handlerArray = this._registeredEventHandlers.get(eventType)
				this._eventLogger.record(event,this._moduleType);
				return handlerArray[0].handler(eventDetails)
			}
		}catch(error){
			event.setEventStatus(EventStatus.FAILED);
			this._eventLogger.record(event,this._moduleType);
			throw error;
		}
		return new Promise(resolve => resolve("Get Listener not registered"));
	}

	@ReadOnly
	scatterGather(events){
		events.forEach(event => this._eventBus.push(event))
		return this._scatterGather(events.length)
	}

	@ReadOnly
	_scatterGather(noOfEvents){
		let noOfEventsToProcess = noOfEvents
		const getEvent = this.generateMultiple(noOfEvents)
		const promiseArray = [] 
		while (noOfEventsToProcess > 0) {
			const event = getEvent.next().value
			event.setEventStatus(EventStatus.PROCESSING);
			const eventDetails = event.getEventDetails();
			const eventType = event.getEventType();
			try{
				if(this._registeredEventHandlers.has(eventType)){
					const handlerArray = this._registeredEventHandlers.get(eventType)
					promiseArray.push(handlerArray[0].handler(eventDetails))
				}else{	
					promiseArray.push(new Promise(resolve => resolve("Get Listener not registered")));
				}
			}catch(error){
				event.setEventStatus(EventStatus.FAILED);
				this._eventLogger.record(event,this._moduleType);
				throw error;
			}
			noOfEventsToProcess--;
		}
		return Promise.all(promiseArray)
	}


}

export default ApiEventBus;
